
import { defineComponent, ref } from 'vue'
import { BaseModal, ModalState } from 'coghent-vue-3-component-library'
import { useI18n } from 'vue-i18n'

export type CCModalType = {
  state: typeof ModalState
}

const CCModalState = ref<CCModalType>({
  state: 'hide',
})

export const useCCModal = () => {
  const updateCCModal = (CCModalInput: CCModalType) => {
    CCModalState.value = CCModalInput
  }

  const closeCCModal = () => {
    updateCCModal({
      state: 'hide',
    })
  }

  const openCCModal = () => {
    updateCCModal({
      state: 'show',
    })
  }

  return {
    closeCCModal,
    openCCModal,
    CCModalState,
  }
}

export default defineComponent({
  components: {
    BaseModal,
  },
  setup() {
    const { closeCCModal, CCModalState } = useCCModal()
    const { t } = useI18n()
    return {
      closeCCModal,
      CCModalState,
      t,
    }
  },
})
