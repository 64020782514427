
import { defineComponent, watch, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { UserStore } from '../stores/UserStore'
import StoreFactory from '../stores/StoreFactory'
import { BaseButton, BaseIcon } from 'coghent-vue-3-component-library'
import { User } from 'coghent-vue-3-component-library'
import { apolloClient, router } from '@/app'
import { storyboxCount } from '@/app'
import { useStorybox, StoryBoxState } from 'coghent-vue-3-component-library'
import useMenu from '@/composables/useMenu'
import { useAuthFeature, useStoryboxFeature } from '@/stores/ConfigStore'
import { useOnBoarding } from '../composables/useOnBoarding'

export default defineComponent({
  name: 'TheHeader',
  components: { BaseButton, BaseIcon },
  setup(props, { emit }) {
    const route = useRoute()
    // const router = useRouter()
    const checkHome = () => route.path === '/'
    const checkTouch = () => route.fullPath.includes('touch=true')
    const { headerItems } = useMenu()

    const isMobile = ref<boolean>(false)
    const isTouchActive = ref<Boolean>(checkTouch())
    const isHomeActive = ref<Boolean>(checkHome())
    const isPavilionActive = ref<Boolean>(route.path === '/pavilion')
    const userStore = StoreFactory.get(UserStore)
    const user: typeof User = userStore.user
    const { toggleOnBoarding } = useOnBoarding()

    watch(
      () => route.path,
      () => {
        isHomeActive.value = checkHome()
        isPavilionActive.value = route.path === '/pavilion'
      }
    )

    onMounted(async () => {
      if (window.innerWidth <= 900) {
        isMobile.value = true
      }
      if (userStore.hasUser) {
        await useStorybox(apolloClient).getStoryboxes()
        storyboxCount.value = StoryBoxState.value.count
      }
    })

    const goToLoginPage = async () => {
      router.push({ path: '/login', query: route.query })
    }

    const goToProfilePage = () => {
      router.push({ path: '/mijnverhalen', query: route.query })
    }

    const goToVerhalenBox = () => {
      router.push({ path: '/mijnverhalen', query: route.query })
    }

    const checkAction = () => {
      if (window.innerWidth <= 900) {
        isMobile.value = true
        emit(`isOpen`, true)
      } else {
        isMobile.value = false
        if (isHomeActive.value) {
          router.go(0)
        }
        if (isTouchActive.value === true) {
          router.push({ path: `/home`, query: { touch: 'true' } })
        } else router.push({ path: `/home` })
      }
      // :href="isTouchActive ? '/home?touch=true' : '/home'"
    }

    const { t } = useI18n()
    return {
      t,
      isHomeActive,
      isTouchActive,
      isPavilionActive,
      goToProfilePage,
      goToVerhalenBox,
      goToLoginPage,
      userStore,
      user,
      route,
      useAuthFeature,
      useStoryboxFeature,
      storyboxCount,
      checkAction,
      isMobile,
      headerItems,
      toggleOnBoarding,
    }
  },
})
