import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = ["onChange"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id", "onUpdate:modelValue", "value", "disabled"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock(_component_VDropdown, {
    placement: "top",
    disabled: _ctx.disabled
  }, {
    popper: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStoryboxes, (storybox, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: index,
            onChange: ($event: any) => (_ctx.setStatusForStorybox(storybox))
          }, [
            _createElementVNode("label", {
              for: storybox.id,
              class: "p-2"
            }, [
              _withDirectives(_createElementVNode("input", {
                id: storybox.id,
                "onUpdate:modelValue": ($event: any) => ((storybox.checked) = $event),
                type: "checkbox",
                value: storybox.name,
                disabled: storybox.added
              }, null, 8, _hoisted_4), [
                [_vModelCheckbox, storybox.checked]
              ]),
              _createTextVNode(" " + _toDisplayString(storybox.name), 1)
            ], 8, _hoisted_3)
          ], 40, _hoisted_2)), [
            [_vShow, _ctx.userStoryboxes.length]
          ])
        }), 128)),
        (!_ctx.userStoryboxes.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t('details.modal.createStorybox')), 1)
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_BaseButton, {
          "custom-style": _ctx.canAddToStoryboxes === true ? 'primary' : 'primaryUnavailable',
          text: _ctx.t('details.modal.addShort'),
          class: "mt-4",
          "icon-shown": false,
          onClick: _ctx.emitButtonClick
        }, null, 8, ["custom-style", "text", "onClick"]), [
          [_directive_close_popper]
        ])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["disabled"]))
}