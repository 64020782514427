
import { defineComponent } from 'vue'
import { BaseButton } from 'coghent-vue-3-component-library'
import { useDisclaimerModal } from './DisclaimerPopUp.vue'

export default defineComponent({
  name: 'DisclaimerButton',
  components: { BaseButton },
  setup: () => {
    const { openDisclaimerModal } = useDisclaimerModal()

    return { openDisclaimerModal }
  },
})
