
import { BaseButton, BaseModal, CircleLoader } from 'coghent-vue-3-component-library'
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { apolloClient, router, storyboxCount } from '@/app'
import StoryBoxCreate from '@/components/StoryBoxCreate.vue'
import { Entity, useStorybox, StoryBoxState } from 'coghent-vue-3-component-library'
import ConfirmationModal, { useConfirmationModal } from '../components/ConfirmationModal.vue'
import InputLabel from '../components/InputLabel.vue'

export enum Language {
  'DUTCH' = 'Nederlands',
  'English' = 'Engels',
  'FRENCH' = 'Français',
  'GERMAN' = 'Deutsch',
  'SPANISH' = 'Español',
}

export default defineComponent({
  components: { BaseButton, BaseModal, StoryBoxCreate, CircleLoader, ConfirmationModal, InputLabel },
  setup() {
    const { t } = useI18n()
    const closeWindow = ref<string>('show')
    const loading = ref<boolean>(false)
    const frames = ref<Array<typeof Entity>>([])
    const { openConfirmationModal, setConfirmationCallback } = useConfirmationModal()

    onMounted(async () => {
      const storyboxId = router.currentRoute.value.params.storyboxId
      if (storyboxId) {
        loading.value = true
        await useStorybox(apolloClient).refreshGetStoryboxesWhenEmpty()
        frames.value = StoryBoxState.value.storyboxes
        storyboxCount.value = StoryBoxState.value.count
        loading.value = true
        await useStorybox(apolloClient).createStoryboxFromEntity(storyboxId)
        loading.value = false
      }
      StoryBoxState.value.activeStorybox ? null : router.push('/mijnverhalen')
    })

    document.body.classList.add('overflow-y-hidden')

    const confirmClose = () => {
      setConfirmationCallback(close)
      openConfirmationModal()
    }

    const close = () => {
      closeWindow.value = 'hide'
      document.body.classList.remove('overflow-y-hidden')
      router.push({ path: '/mijnverhalen' })
    }

    const save = async () => {
      await useStorybox(apolloClient).createNew()
      close()
    }

    onUnmounted(() => {
      document.body.classList.remove('overflow-y-hidden')
    })

    return { t, closeWindow, save, close, frames, loading, StoryBoxState, confirmClose }
  },
})
