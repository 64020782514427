import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "md:flex md:mt-4 gap-8" }
const _hoisted_2 = { class: "w-full p-4 md:p-0" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex justify-end items-center"
}
const _hoisted_4 = {
  key: 1,
  class: "h-fit p-8 flex flex-col w-full justify-center items-center overflow-hidden"
}
const _hoisted_5 = { class: "flex justify-center items-center w-full p-4" }
const _hoisted_6 = {
  key: 2,
  class: "flex items-center flex-col w-full h-full"
}
const _hoisted_7 = { class: "h-fit mt-12 text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_profile_side_menu = _resolveComponent("profile-side-menu")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_profile_list_item = _resolveComponent("profile-list-item")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_confirmation_modal, {
      confirmButtonText: _ctx.t('profile.testimony.deleteConfirmation.yes'),
      declineButtonText: _ctx.t('profile.testimony.deleteConfirmation.no'),
      modalText: _ctx.t('profile.testimony.deleteConfirmation.confirmationText')
    }, null, 8, ["confirmButtonText", "declineButtonText", "modalText"]),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_profile_side_menu),
      _createElementVNode("section", _hoisted_2, [
        (_ctx.pager.pageAmount.value > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BaseIcon, {
                icon: "arrowLeftLine",
                class: "stroke-current p-2 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pager.goToPreviousPage(_ctx.refetchWithProps)))
              }),
              _createElementVNode("p", null, _toDisplayString(`${_ctx.pager.currentPage.value} of ${_ctx.pager.pageAmount.value}`), 1),
              _createVNode(_component_BaseIcon, {
                icon: "arrowRightLine",
                class: "stroke-current p-2 cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pager.goToNextPage(_ctx.refetchWithProps)))
              })
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testimonyList, (testimonyListItem) => {
          return (_openBlock(), _createBlock(_component_profile_list_item, {
            key: testimonyListItem.id,
            "profile-list-item-info": testimonyListItem,
            onDeleteItem: _ctx.confirmDelete
          }, null, 8, ["profile-list-item-info", "onDeleteItem"]))
        }), 128)),
        (_ctx.loadingTestimonies && _ctx.testimonyList.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_CircleLoader)
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loadingTestimonies && _ctx.testimonyList.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.t('profile.testimony.noTestimonies')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}