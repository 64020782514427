import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-background-medium flex flex-col overflow-y-auto sm:pb-0 h-10/12"
}
const _hoisted_2 = { class: "flex flex-col lg:flex-row sm:h-5/6" }
const _hoisted_3 = { class: "flex flex-col bg-background-light h-auto lg:w-1/3 px-4" }
const _hoisted_4 = { class: "text-2xl font-black my-2 text-center lg:text-left lg:ml-6 mt-6" }
const _hoisted_5 = { class: "m-3 lg:ml-6 lg:mt-6" }
const _hoisted_6 = {
  key: 0,
  class: "grid grid-cols-2"
}
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-row lg:flex-col pb-5 overflow-x-auto h-3/6 lg:h-full overflow-y-auto"
}
const _hoisted_9 = { class: "flex relative mb-4 w-60 sm:w-auto mr-4 lg:mr-0" }
const _hoisted_10 = { class: "flex flex-col flex-grow bg-background-medium w-full h-auto lg:h-full lg:w-2/3 pb-12 lg:pb-0 mb-4" }
const _hoisted_11 = {
  key: 0,
  class: "mt-5 flex flex-col gap-2 mx-8 bg-background-light px-4 py-2"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 1,
  class: "col-start-1 inline-block mt-2 mb-4"
}
const _hoisted_15 = { class: "font-bold text-lg mt-5 ml-8" }
const _hoisted_16 = { class: "mt-5 flex flex-col gap-2 px-8 bg-background-medium" }
const _hoisted_17 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 1,
  class: "col-start-1 inline-block mt-2 mb-4"
}
const _hoisted_21 = { class: "bg-background-medium" }
const _hoisted_22 = { class: "font-bold text-lg mt-5 mb-3 ml-8" }
const _hoisted_23 = { class: "mx-5 flex gap-3 mb-4 flex-wrap" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  key: 1,
  class: "bg-background-medium"
}
const _hoisted_26 = { class: "font-bold text-lg mt-5 mb-3 ml-8" }
const _hoisted_27 = { class: "ml-8 flex gap-3 mb-4 flex-wrap" }
const _hoisted_28 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_meta_data = _resolveComponent("base-meta-data")!
  const _component_LazyLoadImage = _resolveComponent("LazyLoadImage")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_copyright_tab = _resolveComponent("copyright-tab")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    large: true,
    scroll: true,
    "modal-state": _ctx.DetailsModalState.state,
    "custom-styles": "z-40",
    onHideModal: _ctx.closeDetailsModal
  }, {
    default: _withCtx(() => [
      (_ctx.entity)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createElementVNode("section", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.entity.title[0]?.value), 1),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_base_meta_data, {
                      "key-word": _ctx.t('details.modal.objectNumber'),
                      type: _ctx.entity.objectNumber[0]?.value,
                      "error-text": _ctx.t('details.modal.unknown')
                    }, null, 8, ["key-word", "type", "error-text"]),
                    (_ctx.objectNames.length != 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('details.modal.objectName')), 1),
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectNames, (item) => {
                              return (_openBlock(), _createElementBlock("li", { key: item }, _toDisplayString(item), 1))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.collectieNaam && _ctx.collectieNaam.nestedMetaData && _ctx.collectieNaam.nestedMetaData.title.length != 0)
                      ? (_openBlock(), _createBlock(_component_base_meta_data, {
                          key: 1,
                          "key-word": _ctx.t('details.modal.collectieNaam'),
                          type: _ctx.collectieNaam.nestedMetaData.title[0].value,
                          "error-text": _ctx.t('details.modal.unknown'),
                          clickable: true,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToRelation(_ctx.collectieNaam.nestedMetaData)))
                        }, null, 8, ["key-word", "type", "error-text"]))
                      : _createCommentVNode("", true),
                    (_ctx.getName(_ctx.entity, 'MaterieelDing.beheerder').name != 'onbekend')
                      ? (_openBlock(), _createBlock(_component_base_meta_data, {
                          key: 2,
                          "key-word": _ctx.t('details.modal.instellingNaam'),
                          type: _ctx.getName(_ctx.entity, 'MaterieelDing.beheerder').name,
                          "error-text": _ctx.t('details.modal.unknown'),
                          clickable: true,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToMetaData('MaterieelDing.beheerder', _ctx.getName(_ctx.entity, 'MaterieelDing.beheerder').name)))
                        }, null, 8, ["key-word", "type", "error-text"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.entity.mediafiles)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.mediafiles, (photo, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: photo }, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_LazyLoadImage, {
                              url: _ctx.generateUrl(photo.transcode_filename || photo.filename, 'full'),
                              "no-image-url": photo.mediatype.audio ? _ctx.audioUrl : _ctx.noImageUrl,
                              "extra-class": "my-6 sm:w-full"
                            }, null, 8, ["url", "no-image-url"]),
                            _createVNode(_component_base_button, {
                              class: "absolute top-5 z-20 mt-3 ml-3",
                              "custom-style": "secondary-round",
                              "custom-icon": "fullscreen",
                              "no-margin": true,
                              "icon-shown": true,
                              onClick: ($event: any) => (_ctx.handleMediaModal(photo.transcode_filename || photo.filename, _ctx.entity.mediafiles[index]))
                            }, null, 8, ["onClick"]),
                            _createVNode(_component_copyright_tab, {
                              class: "absolute top-5 right-0 w-full h-full",
                              infotext: _ctx.t('main.info'),
                              "selected-index": index,
                              mediafiles: _ctx.entity.mediafiles,
                              onOpeningCcmodal: _ctx.openNewCCModal
                            }, null, 8, ["infotext", "selected-index", "mediafiles", "onOpeningCcmodal"])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("section", _hoisted_10, [
                _withDirectives(_createElementVNode("p", { class: "ml-8 m-3 mt-6 lg:mr-10 xl:mt-20" }, _toDisplayString(_ctx.entity.description[0]?.value), 513), [
                  [_vShow, _ctx.entity.description && _ctx.entity.description[0]]
                ]),
                (_ctx.collectieNaam)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("strong", {
                        class: "col-start-1 w-min inline-block",
                        innerHTML: _ctx.t(`${_ctx.collectieNaam.label}`)
                      }, null, 8, _hoisted_12),
                      (_ctx.collectieNaam.nestedMetaData.title.length != 0)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: "col-start-1 font-semibold inline-block hover:underline cursor-pointer",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToRelation(_ctx.collectieNaam.nestedMetaData))),
                            innerHTML: _ctx.t(`${_ctx.collectieNaam.nestedMetaData.title[0].value}`)
                          }, null, 8, _hoisted_13))
                        : _createCommentVNode("", true),
                      (_ctx.collectieNaam.nestedMetaData.description && _ctx.collectieNaam.nestedMetaData.description.length != 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.collectieNaam.nestedMetaData.description[0].value), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.t('details.modal.characteristics')), 1),
                _createElementVNode("ul", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterAllData(_ctx.entity).metadataCollection, (metaType) => {
                    return (_openBlock(), _createElementBlock("li", { key: metaType }, [
                      (!metaType.nested)
                        ? (_openBlock(), _createBlock(_component_base_meta_data, {
                            key: 0,
                            "key-word": _ctx.t(`${metaType.label}`),
                            type: _ctx.concatMetadatValues(metaType.data),
                            "error-text": _ctx.t('details.modal.unknown')
                          }, null, 8, ["key-word", "type", "error-text"]))
                        : _createCommentVNode("", true),
                      (metaType.nested)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("strong", {
                              class: "col-start-1 w-min inline-block",
                              innerHTML: _ctx.t(`${metaType.label}`)
                            }, null, 8, _hoisted_18),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metaType.data, (dataItem) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: dataItem.value,
                                class: _normalizeClass(["ml-5", dataItem.nestedMetaData.metadataCollection.length === 0 ? 'mb-1' : 'mb-5'])
                              }, [
                                (metaType.label !== dataItem.value && dataItem.nestedMetaData.metadataCollection.length === 0)
                                  ? (_openBlock(), _createElementBlock("strong", {
                                      key: 0,
                                      class: "col-start-1 font-semibold inline-block",
                                      innerHTML: _ctx.t(`${dataItem.value}`)
                                    }, null, 8, _hoisted_19))
                                  : _createCommentVNode("", true),
                                (dataItem.nestedMetaData.metadataCollection.length !== 0 && dataItem.nestedMetaData.description && dataItem.nestedMetaData.description[0])
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(dataItem.nestedMetaData.description[0].value ? dataItem.nestedMetaData.description[0].value : ''), 1))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataItem.nestedMetaData.metadataCollection, (metaData, index) => {
                                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metaData.data, (data, dataIndex) => {
                                      return (_openBlock(), _createBlock(_component_base_meta_data, {
                                        key: dataIndex,
                                        "strong-label": false,
                                        "key-word": _ctx.t(`${metaData.label}`),
                                        type: data.value,
                                        "error-text": _ctx.t('details.modal.unknown'),
                                        clickable: _ctx.t(`${metaData.label}`) == 'vervaardiger' ? true : false,
                                        onClick: ($event: any) => (_ctx.goToMetaData(metaData.label, data.value))
                                      }, null, 8, ["key-word", "type", "error-text", "clickable", "onClick"]))
                                    }), 128))
                                  ]))
                                }), 128))
                              ], 2))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("h3", _hoisted_22, _toDisplayString(_ctx.t('details.modal.associations')), 1),
                  _createElementVNode("div", _hoisted_23, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.types, (relation, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        relation
                          ? (_openBlock(), _createElementBlock("div", {
                              key: relation.id,
                              class: _normalizeClass({
                    'flex flex-row flex-wrap px-2 py-2 bg-tag-neutral mb-1 mr-1 bg-opacity-50 cursor-pointer hover:underline': relation.relation != '',
                    'flex flex-row flex-wrap px-2 py-2 bg-tag-neutral mb-1 mr-1 bg-opacity-50 cursor-not-allowed': relation.relation == '',
                  }),
                              onClick: ($event: any) => (_ctx.goToRelation(relation))
                            }, _toDisplayString(relation.label), 11, _hoisted_24))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ])
                ]),
                (_ctx.entity.ldesResource)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("h3", _hoisted_26, _toDisplayString(_ctx.t('details.modal.bron')), 1),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("a", {
                          href: _ctx.entity.ldesResource,
                          target: "_blank",
                          class: "underline cursor-pointer"
                        }, _toDisplayString(_ctx.entity.ldesResource), 9, _hoisted_28)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modal-state", "onHideModal"]))
}