import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "m-8 h-full flex text-center items-center flex-col" }
const _hoisted_2 = { class: "flex flex-wrap font-bold text-4xl" }
const _hoisted_3 = { class: "w-full h-full flex flex-col pb-8 mt-8 items-center overflow-y-scroll" }
const _hoisted_4 = { class: "w-4/5 bg-text-white w-full py-4 px-4 lg:px-8 flex flex-col items-center lg:items-start lg:flex-row text-left mb-6 shadow-md" }
const _hoisted_5 = { class: "w-2/5 flex flex-grow justify-center items-start py-8" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "w-3/5 flex flex-grow flex-col text-left p-8 w-full" }
const _hoisted_8 = { class: "text-base font-normal mb-2 text-touchtable-dark opacity-50" }
const _hoisted_9 = { class: "font-semibold text-lg" }
const _hoisted_10 = {
  key: 0,
  class: "text-base font-normal mb-2 text-touchtable-dark opacity-50"
}
const _hoisted_11 = { class: "flex flex-row flex-wrap gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('myWorks.upload.stepFour.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.setMediafile(),
            alt: ""
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadata, (meta) => {
            return (_openBlock(), _createElementBlock("div", {
              key: meta.key,
              class: "flex flex-col mb-4"
            }, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(meta.text), 1),
              _createElementVNode("h4", _hoisted_9, _toDisplayString(meta.answer !== null ? meta.answer : _ctx.t('details.modal.unknown')), 1)
            ]))
          }), 128)),
          _createElementVNode("div", null, [
            (_ctx.uploadState.relations.length >= 1)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_10, _toDisplayString(_ctx.t(`myWorks.upload.stepFour.relations`)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadState.relations, (relation) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: relation.key,
                  class: "mr-2 bg-tag-neutral flex items-center px-2 py-1"
                }, _toDisplayString(relation.value), 1))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}