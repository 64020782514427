import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a42480e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-fit flex flex-col customParent" }
const _hoisted_2 = { class: "flex flex-grow flex-col lg:flex-row" }
const _hoisted_3 = { class: "lg:w-2/3 w-full lg:mr-6" }
const _hoisted_4 = { class: "text-lg my-2 font-bold" }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = ["ondragenter"]
const _hoisted_7 = ["id", "ondragstart", "ondragend", "ondrop", "draggable"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "flex flex-row w-full py-2" }
const _hoisted_11 = ["id"]
const _hoisted_12 = ["id"]
const _hoisted_13 = ["id", "src"]
const _hoisted_14 = ["id"]
const _hoisted_15 = ["id", "onClick"]
const _hoisted_16 = ["id"]
const _hoisted_17 = ["id"]
const _hoisted_18 = {
  key: 0,
  class: "p-4 flex items-center w-full"
}
const _hoisted_19 = ["id", "placeholder", "value", "onInput", "disabled"]
const _hoisted_20 = { class: "flex flex-row justify-end w-full py-2" }
const _hoisted_21 = ["id"]
const _hoisted_22 = ["title"]
const _hoisted_23 = ["id", "onClick"]
const _hoisted_24 = {
  key: 0,
  class: "my-1.5 h-1 bg-background-dark w-full"
}
const _hoisted_25 = { class: "h-fit object-bottom w-full grid grid-rows-2 grid-cols-1 text-center gap-7 p-4 border border-dashed border-background-dark border-4 mt-8" }
const _hoisted_26 = {
  key: 0,
  class: "cursor-pointer"
}
const _hoisted_27 = { class: "lg:w-1/3 w-full my-4 lg:my-0" }
const _hoisted_28 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_BaseDropDown = _resolveComponent("BaseDropDown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InputLabel = _resolveComponent("InputLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.t('storybox.assets.title') +
            `(${_ctx.StoryBoxState.activeStorybox.assetTimings != undefined ? _ctx.StoryBoxState.activeStorybox.assetTimings.length : 0} ${
              _ctx.StoryBoxState.activeStorybox.assetTimings != undefined && _ctx.StoryBoxState.activeStorybox.assetTimings.length === 10 ? ` maximum` : ``
            })`), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('storybox.assets.selectedAssetsInfo')), 1),
        _withDirectives(_createElementVNode("ul", {
          class: "scroll-smooth w-full my-4 lg:my-0",
          ondragenter: _ctx.dragEnter
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.StoryBoxState.activeStorybox.assets, (asset) => {
            return (_openBlock(), _createElementBlock("li", {
              id: asset.id,
              key: asset,
              class: "rounded-md activeElement w-full my-2 align-middle min-h-16",
              ondragstart: _ctx.dragStart,
              ondragend: _ctx.dragEnd,
              ondrop: _ctx.dragEnd,
              draggable: _ctx.canDrag
            }, [
              _createElementVNode("div", {
                id: asset.id
              }, [
                _createElementVNode("div", {
                  id: asset.id,
                  class: _normalizeClass(["block md:flex rounded-md w-full bg-background-light", _ctx.entityIsPublic(asset) === false ? 'opacity-50' : ''])
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", {
                      id: asset.id,
                      class: "w-16 md:w-20 flex items-center justify-center cursor-move",
                      onMousedown: _cache[0] || (_cache[0] = () => (_ctx.canDrag = true)),
                      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragEnd && _ctx.dragEnd(...args)))
                    }, [
                      _createVNode(_component_base_icon, {
                        id: asset.id,
                        icon: 'dragAndDrop',
                        class: "stroke-current"
                      }, null, 8, ["id"])
                    ], 40, _hoisted_11),
                    _createElementVNode("div", {
                      id: asset.id,
                      class: "w-28 flex justify-center items-center"
                    }, [
                      (asset.mediafiles)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            id: asset.id,
                            class: "w-16 h-16 object-scale-down",
                            src: asset.mediafiles[0] && asset.mediafiles[0].thumbnail_file_location ? asset.mediafiles[0].thumbnail_file_location : `/no-image.png`
                          }, null, 8, _hoisted_13))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_12),
                    _createElementVNode("div", {
                      id: asset.id,
                      class: "flex flex-col justify-center w-full px-2"
                    }, [
                      _createElementVNode("h1", {
                        id: asset.id,
                        class: _normalizeClass(["text-sm md:text-lg font-bold", _ctx.entityIsPublic(asset) === true ? 'hover:underline cursor-pointer' : '']),
                        onClick: () => (_ctx.entityIsPublic(asset) === true ? _ctx.router.push(`/entity/${asset.id}`) : null)
                      }, _toDisplayString(asset.title[0] ? asset.title[0].value : 'asset'), 11, _hoisted_15),
                      _createElementVNode("p", {
                        id: asset.id,
                        class: "text-sm invisible lg:visible"
                      }, _toDisplayString(asset.description[0] && asset.description[0].value != '' ? asset.description[0].value.substr(0, 50) + '..' : ''), 9, _hoisted_16)
                    ], 8, _hoisted_14),
                    _createElementVNode("div", {
                      id: asset.id,
                      class: "hidden flex items-center justify-center items-row-reverse cursor-pointer"
                    }, [
                      _createVNode(_component_base_icon, {
                        id: asset.id,
                        icon: 'info',
                        class: "stroke-current",
                        onClick: () => _ctx.router.push(`/entity/${asset.id}`)
                      }, null, 8, ["id", "onClick"])
                    ], 8, _hoisted_17)
                  ]),
                  (_ctx.useCustomStorySubtitles)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("textarea", {
                          class: _normalizeClass(`p-1 h-8 w-full h-full resize-none`),
                          id: 'description-' + asset.id,
                          maxlength: "150",
                          placeholder: _ctx.t('storybox.assets.customText'),
                          value: _ctx.setAssetProperty(asset, 'description'),
                          onInput: (input) => _ctx.updateAssetProperty(asset, input, 'description'),
                          disabled: _ctx.entityIsPublic(asset) ? false : true
                        }, null, 40, _hoisted_19)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_20, [
                    (_ctx.entityIsPublic(asset) === true && _ctx.assetTimingPresent === true)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          id: asset.id,
                          class: "flex items-center justify-center items-row-reverse cursor-pointer"
                        }, [
                          _createElementVNode("p", {
                            class: "text-xs",
                            title: _ctx.t('storybox.assets.showTimeToolTip')
                          }, _toDisplayString(_ctx.t('storybox.assets.showTime')), 9, _hoisted_22),
                          _createVNode(_component_BaseDropDown, {
                            "number-step": 5,
                            "number-max": 60,
                            active: Number(_ctx.setAssetProperty(asset, 'timing')),
                            style: _normalizeStyle(`p-1.5 rounded-md ml-2 mr-2 bg-text-white`),
                            onSelected: (option) => _ctx.updateAssetProperty(asset, Number(option).toFixed(), 'timing')
                          }, null, 8, ["active", "style", "onSelected"])
                        ], 8, _hoisted_21))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      id: asset.id,
                      class: "w-10 md:w-28 flex items-center justify-center items-row-reverse cursor-pointer",
                      onClick: ($event: any) => (_ctx.deleteAsset(asset))
                    }, [
                      _createVNode(_component_base_icon, {
                        id: asset.id,
                        icon: 'wasteBasket',
                        class: "stroke-current"
                      }, null, 8, ["id"])
                    ], 8, _hoisted_23)
                  ])
                ], 10, _hoisted_9)
              ], 8, _hoisted_8),
              (_ctx.draggingAssetComesBelow === asset.id && _ctx.draggingAssetComesBelow != _ctx.startDragItem && _ctx.canDrag === true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                : _createCommentVNode("", true)
            ], 8, _hoisted_7))
          }), 128))
        ], 8, _hoisted_6), [
          [_vShow, _ctx.StoryBoxState.activeStorybox.assets != undefined]
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("p", null, _toDisplayString(_ctx.t('storybox.assets.addMore')), 1),
          _createElementVNode("strong", {
            class: "cursor-pointer",
            onClick: _cache[2] || (_cache[2] = () => _ctx.router.push('/'))
          }, _toDisplayString(_ctx.t('storybox.assets.search')), 1),
          _createVNode(_component_router_link, {
            to: { path: '/' },
            class: "h-full w-full"
          }, {
            default: _withCtx(() => [
              false
                ? (_openBlock(), _createElementBlock("strong", _hoisted_26, _toDisplayString(_ctx.t('storybox.assets.collection')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_InputLabel, {
          label: _ctx.t('storybox.story.title'),
          labelFor: "storyDescription",
          inputIsRequired: false
        }, null, 8, ["label"]),
        _createElementVNode("textarea", {
          placeholder: _ctx.t('storybox.story.storyPlaceholder'),
          class: "w-full lg:h-5/6 h-48 bg-background-light p-4 resize-none",
          id: "storyDesctiption",
          value: _ctx.StoryBoxState.activeStorybox.description,
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateDescription && _ctx.updateDescription(...args)))
        }, null, 40, _hoisted_28)
      ])
    ])
  ]))
}