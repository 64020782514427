import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0274cd1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "h-full p-8 flex flex-col bg-background-light opacity-70 flex-grow absolute top-0 w-full justify-center items-center overflow-hidden"
}
const _hoisted_3 = { class: "flex justify-center items-center w-full p-4" }
const _hoisted_4 = { class: "customParent" }
const _hoisted_5 = { class: "w-full flex justify-center text-4xl mb-4 font-bold" }
const _hoisted_6 = { class: "flex flex-col lg:flex-row" }
const _hoisted_7 = { class: "mb-4 lg:my-0 w-full" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "object-bottom w-full h-fit pb-8 flex flex-row place-content-end mt-4 sticky bottom-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_input_label = _resolveComponent("input-label")!
  const _component_story_box_create = _resolveComponent("story-box-create")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_confirmation_modal, {
        "modal-text": _ctx.t('storybox.closeConfirmation.confirmationText'),
        "confirm-button-text": _ctx.t('storybox.closeConfirmation.yes'),
        "decline-button-text": _ctx.t('storybox.closeConfirmation.no')
      }, null, 8, ["modal-text", "confirm-button-text", "decline-button-text"])
    ]),
    _createVNode(_component_BaseModal, {
      "custom-z-index": "z-40",
      "modal-state": _ctx.closeWindow,
      large: true,
      scroll: true,
      onHideModal: _ctx.confirmClose
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_CircleLoader)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["h-full p-8 flex flex-col", `${_ctx.loading === true ? 'overflow-y-hidden' : ''}`])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.t('storybox.title')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_input_label, {
                  label: _ctx.t('storybox.step2.storyTitle'),
                  "label-for": "storyboxTitle",
                  "input-is-required": true
                }, null, 8, ["label"]),
                _createElementVNode("input", {
                  id: "storyboxTitle",
                  class: "bg-background-light h-10 w-full p-2",
                  type: "text",
                  value: _ctx.StoryBoxState.activeStorybox != null ? _ctx.StoryBoxState.activeStorybox.title : '',
                  onChange: _cache[0] || (_cache[0] = (event) => (_ctx.StoryBoxState.activeStorybox != null ? (_ctx.StoryBoxState.activeStorybox.title = event.target.value) : ''))
                }, null, 40, _hoisted_8)
              ])
            ]),
            (_ctx.StoryBoxState.activeStorybox != null)
              ? (_openBlock(), _createBlock(_component_story_box_create, {
                  key: 0,
                  loading: _ctx.loading
                }, null, 8, ["loading"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_base_button, {
                text: _ctx.t('storybox.story.close'),
                "on-click": () => _ctx.confirmClose(),
                "custom-style": "secondary",
                "icon-shown": false,
                "custom-icon": "storybox",
                class: "px-2 mx-3 ml-3"
              }, null, 8, ["text", "on-click"]),
              _createVNode(_component_base_button, {
                text: _ctx.t('storybox.story.save'),
                "on-click": () => _ctx.save(),
                "icon-shown": false,
                "custom-icon": "storybox",
                class: "bg-accent-red px-2 mx-3 ml-3"
              }, null, 8, ["text", "on-click"])
            ])
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["modal-state", "onHideModal"])
  ], 64))
}