
import { defineComponent, ref } from 'vue'
import { useOnBoarding } from '../composables/useOnBoarding'
import { BaseModal, BaseIcon } from 'coghent-vue-3-component-library'
import { useI18n } from 'vue-i18n'

type CarouselItem = {
  contentLocation: string
  contentName?: string
  contentDescription?: string
  descriptionLocation?: string
}

type CarouselState = {
  currentCarouselSlide: number
  carouselContent: CarouselItem[]
}

const carouselState = ref<CarouselState>({
  currentCarouselSlide: 1,
  carouselContent: [
    { contentLocation: '/loginOnboarding.png', contentName: 'Aanmelden', contentDescription: 'main.onBoarding.login', descriptionLocation: 'top-20 right-0' },
    { contentLocation: '/loginOnboarding.png', contentName: 'Zoeken', contentDescription: 'main.onBoarding.search', descriptionLocation: 'top-28 right-0' },
    { contentLocation: '/storyboxOnboarding.png', contentName: 'Verhalenbox', contentDescription: 'main.onBoarding.storybox', descriptionLocation: 'top-20 right-0' },
    { contentLocation: '/storiesOnboarding.png', contentName: 'Ga aan de slag', contentDescription: 'main.onBoarding.stories', descriptionLocation: 'top-48 right-0' },
  ],
})

const useCarousel = () => {
  const animationDirection = ref<'next' | 'previous' | undefined>(undefined)

  const getCurrentCarouselContent = () => {
    return carouselState.value.carouselContent[carouselState.value.currentCarouselSlide]
  }

  const nextCarouselSlide = () => {
    animationDirection.value = 'next'
    if (carouselState.value.currentCarouselSlide < carouselState.value.carouselContent.length) {
      carouselState.value.currentCarouselSlide++
    } else {
      carouselState.value.currentCarouselSlide = 1
    }
  }

  const previousCarouselSlide = () => {
    animationDirection.value = 'previous'
    if (carouselState.value.currentCarouselSlide > 1) {
      carouselState.value.currentCarouselSlide--
    } else {
      carouselState.value.currentCarouselSlide = carouselState.value.carouselContent.length
    }
  }

  return {
    getCurrentCarouselContent,
    nextCarouselSlide,
    previousCarouselSlide,
    carouselState,
    animationDirection,
  }
}

export default defineComponent({
  name: 'onBoardingCarousel',
  components: { BaseModal, BaseIcon },
  props: {},
  setup() {
    const { closeOnBoarding } = useOnBoarding()
    const { carouselState, animationDirection, nextCarouselSlide, previousCarouselSlide } = useCarousel()
    const { t } = useI18n()

    return { closeOnBoarding, carouselState, nextCarouselSlide, previousCarouselSlide, animationDirection, t }
  },
})
