
import { defineComponent, onMounted, ref, watch } from 'vue'
import ProfileSideMenu from '../components/ProfileSideMenu.vue'
import ProfileListItem, { ProfileListItemInfo, ProfileListItemType } from '../components/ProfileListItem.vue'
import { GetTestimoniesOfUserDocument, Entity, CircleLoader, Relation, RelationType, DeleteEntityDocument, BaseIcon } from 'coghent-vue-3-component-library'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { parseDateAsLocaleString } from '@/helpers'
import { useI18n } from 'vue-i18n'
import { iiif } from '@/app'
import ConfirmationModal, { useConfirmationModal } from '../components/ConfirmationModal.vue'
import { Pager } from '../composables/pager'

export default defineComponent({
  name: 'TheTestimonyPage',
  components: { ProfileSideMenu, ProfileListItem, CircleLoader, ConfirmationModal, BaseIcon },
  setup() {
    const testimonyList = ref<typeof Entity[]>([])
    const { result: testimonies, refetch: refetchTestimonies, loading: loadingTestimonies } = useQuery(GetTestimoniesOfUserDocument, { limit: 0, skip: 0 })
    const { generateUrl, generateInfoUrl, noImageUrl } = iiif
    const { t } = useI18n()
    const { mutate: deleteItem } = useMutation(DeleteEntityDocument)
    const { setConfirmationCallback, setConfirmationEntityId, ConfirmationModalState, openConfirmationModal } = useConfirmationModal()
    const pager = new Pager(6)

    const parseListItem = (items: typeof Entity[]): ProfileListItemInfo[] => {
      const listItems: ProfileListItemInfo[] = []
      if (listItems) {
        items.forEach((item: typeof Entity) => {
          const listItem: ProfileListItemInfo = {
            id: item.id,
            title: item.linkedParentEntity.title[0].value,
            description: item.description[0].value,
            pictureUrl: generateUrl(item.linkedParentEntity.primary_transcode, 'full'),
            dateCreated: parseDateAsLocaleString(new Date(item.date[0].value)),
            onClickUrl: item.relations[0]?.key.replace('entities/', '/entity/'),
            type: ProfileListItemType.testimony,
            entity: item,
          }
          listItems.push(listItem)
        })
      }
      return listItems
    }

    const refetchWithProps = () => {
      testimonyList.value = []
      refetchTestimonies({ limit: pager.limit.value, skip: pager.skip.value })
    }

    onMounted(() => {
      refetchWithProps()
    })

    watch(
      () => testimonies.value,
      () => {
        if (testimonies.value) {
          testimonyList.value = parseListItem(testimonies.value.GetTestimoniesOfUser.results)
          pager.updateCount(testimonies.value.GetTestimoniesOfUser.count)
        }
      },
      { immediate: true }
    )

    const confirmDelete = (testimonyId: string) => {
      setConfirmationCallback(deleteTestimony)
      setConfirmationEntityId(testimonyId)
      openConfirmationModal()
    }

    const deleteTestimony = (testimonyId: string | undefined = undefined) => {
      const id: string | undefined = testimonyId || ConfirmationModalState.value.confirmationEntityId
      if (id) {
        testimonyList.value = testimonyList.value.filter((listItem: ProfileListItemInfo) => listItem.id != id)
        deleteItem({ id })
      }
    }

    return { testimonyList, loadingTestimonies, t, deleteTestimony, confirmDelete, pager, refetchWithProps }
  },
})
