import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex sm:flex-row flex-col-reverse" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center w-full justify-center my-4 px-6 sm:pl-4 sm:pr-2 md:w-40"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full p-4" }
const _hoisted_5 = { class: "flex flex-row justify-between pb-2" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "pb-2" }
const _hoisted_8 = { class: "text-tag-selected text-opacity-50 flex justify-between" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_story_edit_dropdown = _resolveComponent("story-edit-dropdown")!
  const _component_MyWorksActions = _resolveComponent("MyWorksActions")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(`${_ctx.isLoading ? 'animate-pulse opacity-30' : ''} ${_ctx.isClickable ? 'cursor-pointer' : ''} ${
      _ctx.taginfo && _ctx.tagInfo.title === 'Afgewezen' ? 'opacity-50' : ''
    } bg-neutral-0 rounded-t-md sm:rounded-l-md mb-8 sm:mb-4 w-full flex-col sm:flex-row ${_ctx.profileListItemInfo.pictureUrl ? 'flex' : ''}`),
    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isClickable ? _ctx.router.push(_ctx.profileListItemInfo.onClickUrl) : null))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.profileListItemInfo.pictureUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              class: "h-full object-contain",
              src: _ctx.profileListItemInfo.pictureUrl
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", {
            class: _normalizeClass(["font-bold", [_ctx.itemAction === 'deleted' ? '' : 'cursor-pointer']]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.itemAction === 'deleted' ? null : _ctx.router.push(_ctx.links.visit)))
          }, _toDisplayString(_ctx.profileListItemInfo.title), 3),
          (_ctx.showWorksFeature)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["my-4 sm:my-0 sm:mr-8 px-4 py-0.5 bg-opacity-20 text-opacity-100 font-bold text-sm flex flex-row items-center", _ctx.tagInfo.style])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.tagInfo.style, "mr-2 rounded-full h-2 w-2"])
                }, null, 2),
                _createTextVNode(" " + _toDisplayString(_ctx.tagInfo.title), 1)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.showStoryFeature)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_story_edit_dropdown, {
                  "story-box-info": _ctx.profileListItemInfo,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"])),
                  onUpdate: _ctx.update
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseButton, {
                      "custom-icon": "threedots",
                      "no-margin": true,
                      "custom-style": "ghost-black",
                      "icon-shown": true
                    })
                  ]),
                  _: 1
                }, 8, ["story-box-info", "onUpdate"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.directDeleteFeature)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "flex justify-end px-2",
                onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop","prevent"]))
              }, [
                _createVNode(_component_BaseButton, {
                  "custom-icon": "delete",
                  "no-margin": true,
                  "custom-style": "ghost-black",
                  "icon-shown": true,
                  onClick: _ctx.deleteEntity
                }, null, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.profileListItemInfo.description), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.profileListItemInfo.dateCreated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_ctx.t('profile.storybox.creation-date') + _ctx.profileListItemInfo.dateCreated), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.profileListItemInfo.code && _ctx.showStoryFeature)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("p", null, _toDisplayString(_ctx.t('profile.storybox.code') + _ctx.profileListItemInfo.code), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.showWorksFeature && _ctx.tagInfo.title !== 'Afgewezen')
      ? (_openBlock(), _createBlock(_component_MyWorksActions, {
          key: 0,
          tagInfo: _ctx.tagInfo,
          "onUpdate:tagInfo": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tagInfo) = $event)),
          isLoading: _ctx.isLoading,
          "onUpdate:isLoading": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isLoading) = $event)),
          itemAction: _ctx.itemAction,
          "onUpdate:itemAction": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.itemAction) = $event)),
          "my-works-item": _ctx.profileListItemInfo,
          links: _ctx.links,
          onUpdateTag: _cache[6] || (_cache[6] = () => _ctx.getTagInfo())
        }, null, 8, ["tagInfo", "isLoading", "itemAction", "my-works-item", "links"]))
      : _createCommentVNode("", true)
  ], 2))
}