import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full md:w-3/5 p-8 md:p-0" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col py-2"
}
const _hoisted_3 = {
  key: 0,
  class: "text-text-red text-xs h-4"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col py-2"
}
const _hoisted_5 = {
  key: 0,
  class: "text-text-red text-xs h-4"
}
const _hoisted_6 = {
  key: 2,
  class: "flex flex-col py-2"
}
const _hoisted_7 = { class: "w-full flex justify-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_BoxVisitCode = _resolveComponent("BoxVisitCode")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.hasBoxCode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_input_label, {
            label: _ctx.t('profile.storybox.create.code'),
            labelFor: "storyCode",
            inputIsRequired: true
          }, null, 8, ["label"]),
          _withDirectives(_createElementVNode("input", {
            id: "storyCode",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storyCode) = $event)),
            type: "text",
            maxlength: "8",
            minlength: "8",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.storyCode]
          ]),
          (_ctx.codeInputError)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.codeInputError), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_BoxVisitCode, {
            class: "mt-8",
            code: _ctx.storyCode
          }, null, 8, ["code"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hasBoxCode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_input_label, {
            label: _ctx.t('profile.storybox.create.name'),
            labelFor: "storyName",
            inputIsRequired: true
          }, null, 8, ["label"]),
          _withDirectives(_createElementVNode("input", {
            id: "storyName",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storyName) = $event)),
            type: "text",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.storyName]
          ]),
          (_ctx.nameInputError)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.nameInputError), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hasBoxCode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_input_label, {
            label: _ctx.t('profile.storybox.create.description'),
            labelFor: "storyDescriptio",
            inputIsRequired: false
          }, null, 8, ["label"]),
          _withDirectives(_createElementVNode("textarea", {
            id: "storyDescripton",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storyDescription) = $event)),
            class: "h-24",
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.storyDescription]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_BaseButton, {
        "custom-style": !_ctx.formValid ? 'primaryUnavailable' : 'primary',
        "on-click": _ctx.save,
        class: "max-w-max",
        text: _ctx.t('profile.storybox.create.submit')
      }, null, 8, ["custom-style", "on-click", "text"])
    ])
  ]))
}