
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import useMenu, { MenuItem } from '@/composables/useMenu'
import { router } from '@/app'
import StoreFactory from '@/stores/StoreFactory'
import { UserStore } from '@/stores/UserStore'

export default defineComponent({
  name: 'SideMenu',
  setup(props, { emit }) {
    const { t } = useI18n()
    const menuIsVisible = ref<boolean>(true)
    const menu = useMenu()
    const userStore = StoreFactory.get(UserStore)
    const menuItems = ref<Array<MenuItem>>([])
    const menuItemsBottom = ref<Array<MenuItem>>([])
    document.body.classList.add('overflow-y-hidden')

    const closeMenu = () => {
      document.body.classList.remove('overflow-y-hidden')
      emit(`isOpen`, false)
      menuIsVisible.value = false
    }

    const openMenuItem = (_menutItem: MenuItem) => {
      if (_menutItem.localLink === true) {
        closeMenu()
        router.push(_menutItem.link)
      } else {
        closeMenu()
        window.open(_menutItem.link, '_blank')
      }
    }

    const filterItemsOnAuth = (_menutItems: Array<MenuItem>) => {
      let filtered = _menutItems
      if (userStore.hasUser === false) {
        filtered = []
        for (const item of _menutItems) {
          item.requireAuth === false ? filtered.push(item) : null
        }
      }
      return filtered
    }

    const filterItemsOnVisibility = (_menutItems: Array<MenuItem>) => {
      let filtered = []
      for (const item of _menutItems) {
        item.isVisible === true ? filtered.push(item) : null
      }

      return filtered
    }

    watch(menuIsVisible, (isOpen: boolean) => {
      if (isOpen === true) {
        menuItems.value = filterItemsOnAuth(menu.mobileItems)
        menuItemsBottom.value = filterItemsOnAuth(menu.bottomMobileItems)
      }
    })

    const init = () => {
      menuItems.value = filterItemsOnAuth(menu.mobileItems)
      menuItems.value = filterItemsOnVisibility(menu.mobileItems)
      menuItemsBottom.value = filterItemsOnAuth(menu.bottomMobileItems)
      menuItemsBottom.value = filterItemsOnVisibility(menu.bottomMobileItems)
      console.log(`userStore`, userStore.hasUser)
      console.log(`userStore`, userStore.user.value)
      console.log(`userStore`, userStore.user.name)
    }

    init()

    return {
      t,
      menuIsVisible,
      closeMenu,
      openMenuItem,
      filterItemsOnAuth,
      menuItems,
      menuItemsBottom,
    }
  },
})
