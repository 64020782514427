
import { defineComponent } from 'vue'
import { CardComponent, BaseIcon } from 'coghent-vue-3-component-library'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import useSocials, { SocialLink } from '@/composables/useSocials'
import { useCookieConsent } from 'coghent-vue-3-component-library'

export default defineComponent({
  name: 'TheFooter',
  components: {
    CardComponent,
    BaseIcon,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const { all: allSocials } = useSocials()
    const { showMinimal } = useCookieConsent()

    const toSocial = (_social: SocialLink) => window.open(_social.link, '_blank')

    const openMinimalConsentManager = () => {
      showMinimal()
    }

    return {
      t,
      route,
      allSocials,
      toSocial,
      openMinimalConsentManager,
    }
  },
})
