import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex lg:gap-8 flex-col" }
const _hoisted_2 = { class: "pb-4 flex justify-end mt-4" }
const _hoisted_3 = { class: "flex flex-col md:flex-row" }
const _hoisted_4 = { class: "w-full p-4 md:ml-8 md:p-0" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex justify-end items-center"
}
const _hoisted_6 = {
  key: 1,
  class: "h-fit p-8 flex flex-col w-full justify-center items-center overflow-hidden"
}
const _hoisted_7 = { class: "flex justify-center items-center w-full p-4" }
const _hoisted_8 = {
  key: 2,
  class: "flex items-center flex-col w-full h-full"
}
const _hoisted_9 = {
  key: 0,
  class: "h-fit mt-12 text-xl"
}
const _hoisted_10 = { class: "flex justify-center items-center h-full lg:mt-0 mt-8" }
const _hoisted_11 = { class: "md:hidden fixed bottom-16 right-10 bg-accent-purple rounded-full p-4 flex justify-center items-center shadow-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_profile_side_menu = _resolveComponent("profile-side-menu")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_profile_list_item = _resolveComponent("profile-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmationModal, {
      modalText: _ctx.t('myWorks.overview.deleteConfirmation.confirmationText'),
      confirmButtonText: _ctx.t('myWorks.overview.deleteConfirmation.yes'),
      declineButtonText: _ctx.t('myWorks.overview.deleteConfirmation.no')
    }, null, 8, ["modalText", "confirmButtonText", "declineButtonText"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_base_button, {
          class: "hidden md:flex text-text-white",
          "on-click": () => _ctx.router.push(_ctx.uploadRoute),
          "icon-shown": true,
          "custom-icon": "newItem",
          text: _ctx.t(`myWorks.upload.title`)
        }, null, 8, ["on-click", "text"])
      ]),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_profile_side_menu),
        _createElementVNode("section", _hoisted_4, [
          (_ctx.myWorks.length !== 0 && _ctx.pager.pageAmount.value > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_BaseIcon, {
                  icon: "arrowLeftLine",
                  class: "stroke-current p-2 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pager.goToPreviousPage(_ctx.fetchData)))
                }),
                _createElementVNode("p", null, _toDisplayString(`${_ctx.pager.currentPage.value} of ${_ctx.pager.pageAmount.value}`), 1),
                _createVNode(_component_BaseIcon, {
                  icon: "arrowRightLine",
                  class: "stroke-current p-2 cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pager.goToNextPage(_ctx.fetchData)))
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isLoading && _ctx.myWorks.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_CircleLoader)
                ])
              ]))
            : _createCommentVNode("", true),
          ((!_ctx.isLoading && _ctx.myWorks.length === 0) || _ctx.myWorks === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                true
                  ? (_openBlock(), _createElementBlock("h1", _hoisted_9, _toDisplayString(_ctx.t(`myWorks.upload.noItems`)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createVNode(_component_base_button, {
                    text: _ctx.t('flow.upload'),
                    "on-click": () => _ctx.router.push(`/upload`),
                    "custom-style": "primary",
                    "icon-shown": true,
                    "custom-icon": "newItem",
                    class: "px-2 mx-3 ml-3"
                  }, null, 8, ["text", "on-click"]), [
                    [_vShow, false]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myWorks, (work, index) => {
            return (_openBlock(), _createBlock(_component_profile_list_item, {
              class: "sm:h-40",
              key: index,
              "profile-list-item-info": work
            }, null, 8, ["profile-list-item-info"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_BaseIcon, {
          icon: "newItem",
          class: "stroke-current p-1 cursor-pointer text-text-white",
          onClick: _cache[2] || (_cache[2] = () => _ctx.router.push(`/upload`))
        })
      ])
    ])
  ], 64))
}