
import ProfileListItem, { ProfileListItemInfo, ProfileListItemType } from '@/components/ProfileListItem.vue'
import { defineComponent, ref, watch } from 'vue'
import ProfileSideMenu from '../components/ProfileSideMenu.vue'
import { BaseButton, useStorybox, BaseIcon } from 'coghent-vue-3-component-library'
import { apolloClient, router } from '@/app'
import { useI18n } from 'vue-i18n'
import { StoryBoxState, CircleLoader } from 'coghent-vue-3-component-library'
import { Entity, getMetadataOfTypeFromEntity } from 'coghent-vue-3-component-library'
import { storyboxDataIsUpdated } from 'coghent-vue-3-component-library'
import { storyboxCount } from '@/app'
import QRCodeModal from '../components/QRCodeModal.vue'
import ConfirmationModal from '../components/ConfirmationModal.vue'
import { Pager } from '@/composables/pager'

export default defineComponent({
  name: 'TheStoriesPage',
  components: { ProfileSideMenu, ProfileListItem, BaseButton, CircleLoader, QRCodeModal, BaseIcon, ConfirmationModal },
  setup() {
    const { t } = useI18n()
    const storyBoxItems = ref<ProfileListItemInfo[]>([])
    const loading = ref<Boolean>(false)
    const pager = new Pager(6)

    watch(storyboxDataIsUpdated, async (status: boolean) => {
      if (status === true) {
        await getStoryBoxes(true)
        storyboxDataIsUpdated.value = false
      }
    })

    const getStoryBoxes = async (_skipGetNew = false) => {
      const storyBoxItemsToDisplay = []
      storyBoxItems.value = []
      loading.value = true
      if (!_skipGetNew) {
        await useStorybox(apolloClient).getStoryboxes(pager.limit, pager.skip)
        pager.updateCount(StoryBoxState.value.count)
        storyboxCount.value = StoryBoxState.value.count
      }
      storyboxCount.value = StoryBoxState.value.count
      for (const _box of StoryBoxState.value.storyboxes) {
        const title = getMetadataOfTypeFromEntity(_box, `title`)
        const description = getMetadataOfTypeFromEntity(_box, `description`)
        storyBoxItemsToDisplay.push({
          id: _box.id,
          title: title ? title.value : _box.id,
          description: description ? description.value : '',
          code: undefined,
          onClickUrl: `/mijnverhalen/${_box.id}`,
          type: ProfileListItemType.story,
        } as ProfileListItemInfo)
      }
      storyBoxItems.value = storyBoxItemsToDisplay
      loading.value = false
    }

    getStoryBoxes()

    return {
      storyBoxItems,
      loading,
      t,
      router,
      getStoryBoxes,
      pager,
    }
  },
})
