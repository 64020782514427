
import { defineComponent, ref, watch } from 'vue'
import TheGrid from './TheGrid.vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import { GetCreatorByIdDocument, GetEntityByIdDocument, BaseButton, BaseMetaData, BaseMetaDataTab, Relation } from 'coghent-vue-3-component-library'
import { useHistory } from './BreadCrumbs.vue'

const asString = (x: string | string[]) => (Array.isArray(x) ? x[0] : x)

export type Metadata = {
  key: string
  value: string
}

export type Person = {
  fullName?: string
  extraInfo?: Metadata[]
}

export default defineComponent({
  name: 'CreatorDetails',
  components: {
    BaseButton,
    BaseMetaData,
    BaseMetaDataTab,
    TheGrid,
  },
  props: {},
  setup: () => {
    const { t } = useI18n()
    const id = asString(useRoute().params['creatorID'])
    const { result, onResult, onError } = useQuery(GetCreatorByIdDocument, { id })
    const { result: additionalMetaDataResult, onResult: onAdditionalMetaDataResult, refetch: additionalMetaDataRefetch } = useQuery(GetEntityByIdDocument, { id: '' })
    const router = useRouter()
    const route = useRoute()
    const metadata = ref<Metadata[]>()
    const person = ref<Person>()
    const tabs: Array<string> = ['Design Museum Gent', 'STAM', 'Industriemuseum']
    const additionalInfoIds: Array<string> = []
    const { currentPage } = useHistory()

    const goToPreviousPage = () => {
      router.go(-1)
    }

    const getNameFromMetadata = (key: string) => {
      if (metadata.value) {
        const foundItem = metadata.value.find((element) => element.key == key)
        metadata.value = metadata.value.filter((element) => element != foundItem)
        return foundItem?.value
      }
    }

    const createObjectFromAdditionalData = (entity: any): Array<Metadata> => {
      const objects: Array<Metadata> = []
      entity.metadataCollection.forEach((collection: any) => {
        const dataObject: Metadata = {
          key: entity.type + '_' + collection.label,
          value: collection.data[0].value,
        }
        objects.push(dataObject)
      })
      return objects
    }

    const getDataFromRelations = (relations: typeof Relation[], relationLabels: string[]) => {
      relations.forEach((relation) => {
        if (relationLabels.find((relationlabel: string) => relationlabel == relation.label) && relation.label && relation.value) {
          const dataObject: Metadata = {
            key: relation.label,
            value: relation.value,
          }
          if (person.value?.extraInfo) {
            person.value?.extraInfo.push(dataObject)
          } else if (person.value) {
            person.value.extraInfo = [dataObject]
          }
        }
      })
    }

    onResult((queryResult: any) => {
      if (!queryResult.error && queryResult.data.Entity) {
        const tempMetadata: Array<Metadata> = queryResult.data.Entity?.metadata
        metadata.value = tempMetadata.filter((item) => item.value != '')
        person.value = {
          fullName: getNameFromMetadata('fullname'),
          extraInfo: metadata.value,
        }

        getDataFromRelations(queryResult.data.Entity.relations, ['geslacht'])
        queryResult.data.Entity?.relations.forEach((relation: typeof Relation) => {
          if (relation.label == 'heeftGeboorte' || relation.label == 'heeftOverlijden') {
            additionalInfoIds.push(relation.key)
          }
        })

        if (additionalInfoIds) {
          additionalInfoIds.forEach((url) => {
            const id = url.replace('entities/', '')
            additionalMetaDataRefetch({ id })
          })
        }
      }
    })

    onError(() => {
      router.push({ path: '/entity/not-found', query: route.query })
    })

    onAdditionalMetaDataResult((queryResult) => {
      if (queryResult.data?.Entity) {
        const entity = queryResult.data.Entity
        const infoArray: Array<Metadata> = createObjectFromAdditionalData(entity)

        infoArray.forEach((info: Metadata) => {
          if (person.value?.extraInfo && !person.value.extraInfo.map((el) => el.key).includes(info.key)) {
            person.value?.extraInfo.push(info)
          } else if (person.value) {
            person.value.extraInfo = metadata.value
          }
        })
      }
    })

    watch(
      () => metadata?.value?.length,
      () => {
        if (metadata?.value) {
          let ordering: any = {}
          const sortOrder = ['firstname', 'lastname', 'birth_datum', 'birth_plaats', 'death_datum', 'death_plaats']
          for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i

          metadata.value.sort(function (a: Metadata, b: Metadata) {
            return ordering[a.key] - ordering[b.key]
          })
        }
      }
    )

    return {
      t,
      tabs,
      goToPreviousPage,
      route,
      person,
      result,
      id,
      currentPage,
    }
  },
})
