import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center p-4 sm:p-0" }
const _hoisted_2 = { class: "md:text-5xl sm:text-4xl text-3xl font-bold w-full sm:w-8/12 text-center py-10 block leading-normal" }
const _hoisted_3 = { class: "text-accent-purple" }
const _hoisted_4 = { class: "w-full py-6 flex flex-col lg:flex-row justify-center items-center relative" }
const _hoisted_5 = { class: "w-full sm:w-8/12" }
const _hoisted_6 = {
  key: 1,
  class: "flex w-full flex-col items-center justify-center"
}
const _hoisted_7 = { class: "w-full bg-text-dark h-full left-0 top-0 group-hover:opacity-50 opacity-0 absolute rounded-md" }
const _hoisted_8 = { class: "absolute w-full h-full max-h-full left-0 top-0 group-hover:opacity-100 opacity-0 overflow-hidden" }
const _hoisted_9 = { class: "w-full h-full flex flex-col items-center justify-center text-center text-text-white break-words" }
const _hoisted_10 = {
  key: 0,
  class: "opacity-100 mb-2 px-10 font-bold"
}
const _hoisted_11 = {
  key: 1,
  id: "description",
  class: "opacity-100 px-10"
}
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_search = _resolveComponent("base-search")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_the_masonry = _resolveComponent("the-masonry")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h1", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.t('main.title')) + " ", 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('main.rich')), 1)
    ], 512), [
      [_vShow, _ctx.defaultRelations.length === 0 && !_ctx.noHeader]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_base_search, {
          modelValue: _ctx.searchQueryForInput,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQueryForInput) = $event)),
          loading: _ctx.loading,
          "search-label": _ctx.t('main.search'),
          onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getData(true))),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.getData(true)), ["enter"])),
          onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showKeyboard())),
          onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hideKeyboard()))
        }, null, 8, ["modelValue", "loading", "search-label"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.route.query.touch ? 'transform scale-150' : 'lg:absolute lg:right-0')
      }, [
        _createVNode(_component_base_button, {
          class: "inline⁻block w-max ml-10",
          text: _ctx.t('buttons.surprise'),
          "custom-style": "ghost-black",
          "custom-icon": "surprise",
          "icon-shown": true,
          "on-click": () => _ctx.resetQuery()
        }, null, 8, ["text", "on-click"])
      ], 2)
    ], 512), [
      [_vShow, _ctx.defaultRelations.length === 0 && !_ctx.noHeader]
    ]),
    (!_ctx.noFilters)
      ? (_openBlock(), _createBlock(_component_Filter, {
          key: 0,
          selected: _ctx.selectedFilters,
          loading: _ctx.loading,
          "filter-all": _ctx.t('buttons.all-works'),
          filters: _ctx.relationData,
          onNewSelected: _ctx.updateSelectedFilters
        }, null, 8, ["selected", "loading", "filter-all", "filters", "onNewSelected"]))
      : _createCommentVNode("", true),
    (_ctx.entityData && _ctx.entityData.Entities)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_the_masonry, {
            ref: "masonry",
            small: _ctx.small,
            entities: _ctx.entityData ? _ctx.entityData.Entities : {},
            loading: _ctx.loading,
            "items-each-load": _ctx.limit,
            "end-of-data": _ctx.endOfData,
            "generate-url": _ctx.generateUrl,
            onLoadMore: _ctx.loadMore
          }, {
            tile: _withCtx(({ entity, tile, small }) => [
              _createVNode(_component_router_link, {
                to: { path: '/entity/' + entity.object_id, query: _ctx.route.query },
                class: "absolute top-0 left-0 z-30 h-full w-full",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (!_ctx.route.params.entityID ? _ctx.clearHistory() : undefined))
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("span", _hoisted_7, null, 512), [
                    [_vShow, tile.mediafiles[0] !== 'placeholder']
                  ]),
                  _withDirectives(_createElementVNode("span", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      (entity.title && entity.title[0])
                        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(entity.title[0].value), 1))
                        : _createCommentVNode("", true),
                      (entity.description && entity.description[0] && tile.type === 'SingleImage')
                        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(entity.description[0].value.length > 100 ? entity.description[0].value.substring(0, 100) + '...' : entity.description[0].value), 1))
                        : _createCommentVNode("", true),
                      _createVNode(_component_base_button, {
                        text: "Lees meer",
                        "custom-style": "ghost-white",
                        "icon-shown": true,
                        "icon-left": false,
                        "custom-icon": "arrowRightLine"
                      }),
                      (!_ctx.route.query.touch)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            onClick: _withModifiers(() => _ctx.copyUrl(entity.id), ["prevent"])
                          }, [
                            _createVNode(_component_base_button, {
                              class: "z-10 mt-3 ml-3",
                              "custom-style": "secondary-round",
                              "icon-shown": true,
                              "custom-icon": "link",
                              "no-margin": true
                            })
                          ], 8, _hoisted_12))
                        : _createCommentVNode("", true)
                    ])
                  ], 512), [
                    [_vShow, !small && tile.mediafiles[0] !== 'placeholder']
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["small", "entities", "loading", "items-each-load", "end-of-data", "generate-url", "onLoadMore"])
        ]))
      : _createCommentVNode("", true)
  ]))
}