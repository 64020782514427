import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "text-xl font-bold" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    "modal-state": _ctx.offensiveContentModalState.modalstate,
    onHideModal: _ctx.closeOffensiveContentModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('details.offensiveContent.title')), 1),
        _createElementVNode("p", {
          innerHTML: _ctx.t('details.offensiveContent.description')
        }, null, 8, _hoisted_3)
      ])
    ]),
    _: 1
  }, 8, ["modal-state", "onHideModal"]))
}