import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}
const _hoisted_2 = { class: "grid sm:grid-cols-2 sm:gap-3 h-auto w-full px-3" }
const _hoisted_3 = { class: "pb-0 sm:pb-24" }
const _hoisted_4 = { class: "md:text-5xl sm:text-4xl text-3xl font-bold w-full py-10 block leading-normal" }
const _hoisted_5 = {
  key: 0,
  class: "md:text-lg sm:text-md text-sm w-full block leading-normal pb-10 md:pb-0"
}
const _hoisted_6 = { class: "flex flex-col w-full px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_grid = _resolveComponent("the-grid")!

  return (_ctx.result)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("section", null, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.result.Entity?.title[0]?.value), 1),
              (_ctx.result.Entity.description.length)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.result.Entity.description[0].value), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_the_grid, {
            small: false,
            "no-filters": true,
            "default-relations": [`entities/${_ctx.id}`],
            "no-relations": true
          }, null, 8, ["default-relations"])
        ])
      ]))
    : _createCommentVNode("", true)
}