
import { defineComponent, reactive, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ModalState } from './base/Modal.vue'
import { BaseButton, CopyrightTab, LazyLoadImage, BaseMetaData, BaseModal, useMediaModal, MediaFile, Metadata, MetadataCollection, Relation } from 'coghent-vue-3-component-library'
import { useCCModal } from './CreativeModal.vue'
import useFilter from '@/composables/useFilter'
import { itemsInBasket } from '@/composables/useStoryBox'
import { iiif } from '@/app'
import { useHistory } from './BreadCrumbs.vue'
import { HeadAttrs, useHead } from '@vueuse/head'
import { getFirstValueOfPropertyFromEntity, getFirstMediafileWithFilelocationOfEntity } from 'coghent-vue-3-component-library'
import { setKeyAsId } from '../helpers'
import { useGoogleFeature, useStoryboxFeature } from '@/stores/ConfigStore'

export type DetailsModalType = {
  state: ModalState
}

export type FullscreenModalType = {
  state: ModalState
}

type NestedDataObject = {
  description: []
  id: string
  mediafiles: []
  metadataCollection: Array<typeof MetadataCollection>
  objectNumber: []
  relations: Array<typeof Relation>
  title: []
  type: string
  types: Array<TypeObject>
  __typename: string
}

type TypeObject = {
  label: string
  id: string
  relation: string
}

const entity = ref<any>()
const collectieNaam = ref<any>()
const objectNames = ref<any>([])

const DetailsModalState = ref<DetailsModalType>({
  state: 'hide',
})

export const useDetailsModal = () => {
  const updateDetailsModal = (DetailsModalInput: DetailsModalType) => {
    DetailsModalState.value = DetailsModalInput
  }

  const closeDetailsModal = () => {
    updateDetailsModal({
      state: 'hide',
    })
  }

  const openDetailsModal = () => {
    updateDetailsModal({
      state: 'show',
    })
  }

  const createTypesFromMetadata = (_metadata: Array<typeof Metadata>) => {
    const newTypes: Array<TypeObject> = []
    if (_metadata.length != 0) {
      for (const _meta of _metadata) {
        if (_meta.nestedMetaData) {
          newTypes.push({
            id: _meta.nestedMetaData.id,
            label: _meta.value,
            relation: _meta.__typename,
          } as TypeObject)
        }
      }
    }
    return newTypes
  }

  const removeDuplicateObjectsFromArray = (array: Array<any>): Array<any> => {
    const ids = array.map((o) => setKeyAsId(o.id))
    const filtered = array.filter(({ id }, index) => !ids.includes(setKeyAsId(id), index + 1))
    return filtered
  }

  const setEntity = (data: any) => {
    if (!data) return
    entity.value = data
    entity.value.metadataCollection = entity.value.metadataCollection.filter((collection: any) => collection.label != 'vervaardiger')
    entity.value = useFilter().removeMetadataCollectionByLabel(entity.value, ['publication_status'])
    collectieNaam.value = useFilter().getParentCollectionByNameIfTitle(entity.value, 'Collectie.naam')
    const objectNameData = useFilter().getDataOfCollection(entity.value, 'Entiteit.classificatie')
    const objectNamesData = useFilter().getMetadataCollectionByLabel(objectNameData, 'objectnaam')
    objectNames.value = useFilter().getObjectNames(objectNamesData)
    const newTypes = createTypesFromMetadata(objectNamesData)
    entity.value.types = removeDuplicateObjectsFromArray(entity.value.types.concat(newTypes))
    entity.value.type
  }

  return {
    closeDetailsModal,
    openDetailsModal,
    DetailsModalState,
    setEntity,
    entity,
    collectieNaam,
    objectNames,
  }
}

export default defineComponent({
  components: {
    BaseModal,
    BaseButton,
    CopyrightTab,
    LazyLoadImage,
    BaseMetaData,
  },
  setup(props) {
    const { closeDetailsModal, DetailsModalState } = useDetailsModal()
    let IIIfImageUrl: string = ''
    const { openCCModal } = useCCModal()
    const { generateUrl, generateInfoUrl, noImageUrl, audioUrl } = iiif
    const router = useRouter()
    const route = useRoute()
    const { history } = useHistory()
    const { openMediaModal, setMediaModalImageUrl, setMediaModalFile } = useMediaModal()
    const head = reactive<Array<HeadAttrs>>([])

    useHead({ meta: head })

    watch(entity, async () => {
      if (useGoogleFeature.value === true) {
        let title = getFirstValueOfPropertyFromEntity(entity.value, `title`)
        let description = getFirstValueOfPropertyFromEntity(entity.value, `description`)
        let image = ''
        let url = window.location.href
        
        entity.value.primary_transcode ? (image = entity.value.primary_transcode) : ''
        const media = await getFirstMediafileWithFilelocationOfEntity(entity.value)
        media && media.original_file_location ? (image = media.original_file_location) : ``
        head.push(
          ...[
            {
              property: 'og:title',
              content: title ? title.value : '',
            },
            {
              property: 'og:description',
              content: description ? description.value : '',
            },
            {
              property: 'og:image',
              content: image,
            },
            {
              property: 'og:url',
              content: url
            }
          ]
        )
      }
    })

    const handleMediaModal = (filename: string, mediaFile: typeof MediaFile) => {
      setMediaModalImageUrl(generateInfoUrl(filename, 'full'))
      setMediaModalFile(mediaFile)
      openMediaModal()
    }

    const openNewCCModal = () => {
      openCCModal()
      closeDetailsModal()
    }

    const replaceDotsWithOnbekend = (_string: string) => {
      let returnValue = _string
      if (_string == '..') returnValue = 'onbekend'
      return returnValue
    }

    const concatMetadatValues = (input: typeof Metadata[]): string => {
      let concatString: string = ''
      input.forEach((meta: typeof Metadata) => {
        if (concatString === '') {
          concatString = meta.value ? meta.value : t('details.modal.unknown')
          concatString = replaceDotsWithOnbekend(concatString)
        } else {
          concatString = `${concatString}, ${replaceDotsWithOnbekend(meta.value as string)}`
        }
      })

      return concatString
    }

    const { t } = useI18n()

    const getObjectName = (metadataCollection: any[]) => {
      const objectNameArray: string[] = []
      try {
        metadataCollection.forEach((metadata) => {
          if (metadata.label === 'Entiteit.classificatie') {
            metadata.data.forEach((metadata2: any) => {
              metadata2.nestedMetaData.metadataCollection.forEach((element: any) => {
                if (element.label === 'objectnaam') {
                  element.data.forEach((element2: any) => {
                    objectNameArray.push(element2.value)
                  })
                }
              })
            })
          }
        })

        return [...new Set(objectNameArray)].join(',')
      } catch (error) {
        return 'onbekend'
      }
    }

    const filterAllData = (_entity: NestedDataObject) => {
      // debugger
      let entity = {} as NestedDataObject
      Object.assign(entity, _entity)
      const parentLabels = ['vervaardiging.plaats', 'Collectie.naam', 'MaterieelDing.beheerder']
      entity = useFilter().setMetadataToOneRelationDown(entity, 'Entiteit.wordtNaarVerwezenDoor')
      entity.metadataCollection = useFilter().removeParentCollections(entity.metadataCollection as Array<typeof MetadataCollection>, parentLabels)
      entity = useFilter().removeChildByLabel(entity, 'Collectie.naam', 'MaterieelDing.beheerder')
      entity = useFilter().removeChildByLabel(entity, 'Collectie.naam', 'Entiteit.classificatie')
      entity = useFilter().removeChildByLabel(entity, 'Collectie.naam', 'GecureerdeCollectie.bestaatUit')
      entity = useFilter().removeChildByLabel(entity, 'Collectie.naam', 'MensgemaaktObject.draagt')
      entity = useFilter().removeChildByLabel(entity, 'Entiteit.classificatie', 'objectnaam')
      entity = useFilter().removeChildByLabel(entity, 'inhoud.persoon.naam', 'achternaam')
      entity = useFilter().removeParentsWithoutData(entity)
      entity = useFilter().removeMetadataCollectionFromNestedMetadata(entity, 'inhoud.persoon.naam')
      return entity
    }

    const getName = (_entity: NestedDataObject, _label: string) => {
      let name = 'onbekend'
      let relation = ''
      let id = ''
      const collection = _entity.metadataCollection.filter((_collection) => _collection.label == _label)
      if (collection && collection[0]) {
        name = collection[0].data?.[0]?.value as string
        relation = collection[0].data?.[0]?.label as string
      }
      const relationType = _entity.types.filter((_type) => _type.label == name && _type.relation == relation)
      if (relationType.length > 0) {
        id = relationType[0].id.replace('entities/', '')
      }
      return { name: name, id: id }
    }

    const createLink = (linkId: string, label: string): string => {
      const pageName: 'creator' | 'relation' = label == 'vervaardiger' ? 'creator' : 'relation'
      let link = pageName && linkId ? `/${pageName}/${linkId}` : ''
      return link
    }

    const goToRelation = (metaData: any) => {
      closeDetailsModal()
      const id = metaData.id.replace('entities/', '')
      let routerLink: string = createLink(id, metaData.relation)
      router.push({ path: routerLink, query: route.query })
    }

    const goToMetaData = (_label: string, _value: string) => {
      const relation = useFilter().getRelation(entity.value, _label, _value)
      if (relation) {
        const id = relation.key.replace('entities/', '')
        const routerLink: string = createLink(id, _label)
        router.push({ path: routerLink, query: route.query })
        closeDetailsModal()
      }
    }

    return {
      getObjectName,
      concatMetadatValues,
      closeDetailsModal,
      DetailsModalState,
      entity,
      t,
      openNewCCModal,
      LazyLoadImage,
      generateUrl,
      generateInfoUrl,
      router,
      IIIfImageUrl,
      filterAllData,
      goToMetaData,
      getName,
      collectieNaam,
      objectNames,
      route,
      itemsInBasket,
      goToRelation,
      noImageUrl,
      useStoryboxFeature,
      handleMediaModal,
      audioUrl,
    }
  },
  methods: {
    getCreatorId() {
      const metadata: Array<any> = entity.value.metadataCollection
      const productionData: Array<any> = metadata.find((data) => data.label == 'MaterieelDing.productie').data
      const productionDataRelations: Array<any> = productionData.find((data) => data.label == 'MaterieelDing.productie').nestedMetaData.relations
      const creatorId: string = productionDataRelations.find((relation) => relation.label == 'vervaardiger').key.split('/')[1]
      return creatorId
    },
  },
})
