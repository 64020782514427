
import { defineComponent, ref, PropType, watch, onMounted } from 'vue'
import { Entity, BaseButton } from 'coghent-vue-3-component-library'
import { StoryBoxState, useStorybox, getMediaTypeByfilename } from 'coghent-vue-3-component-library'
import { apolloClient } from '@/app'
import useClipboard from 'vue-clipboard3'
import { UserStore } from '@/stores/UserStore'
import StoreFactory from '@/stores/StoreFactory'
import { useStoryboxFeature } from '@/stores/ConfigStore'
import AddAssetToStoryboxDropdown from './AddAssetToStoryboxDropdown.vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate } from 'vue-router'

export default defineComponent({
  components: {
    BaseButton,
    AddAssetToStoryboxDropdown,
  },
  props: {
    entity: {
      type: Object as PropType<typeof Entity>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const assetIsInAStorybox = ref<boolean>(false)
    const storyboxDdOpen = ref<boolean>(false)
    const canAddToStoryBox = ref<boolean>(false)
    const { toClipboard } = useClipboard()
    const isCopied = ref<boolean>(false)
    const userStore = StoreFactory.get(UserStore)

    const checkAssetIsInAStorybox = async () => {
      new Promise((resolve, reject) => {
        for (const box of StoryBoxState.value.storyboxes) {
          const found = useStorybox(apolloClient).assetIsInStorybox(props.entity, box.id)
          found && found.key ? resolve(true) : null
        }
        resolve(false)
      }).then((val) => {
        assetIsInAStorybox.value = val as boolean
      })
    }

    const addAssetToStorybox = async (_storyBoxIds: Array<string>) => {
      for (const _box of StoryBoxState.value.storyboxes) {
        if (_storyBoxIds.includes(_box.id)) {
          await useStorybox(apolloClient).assetToStorybox(_box.id, props.entity.id)
        }
      }
      await useStorybox(apolloClient).getStoryboxes()
      checkAssetIsInAStorybox()
    }

    watch(
      () => props.entity,
      async () => {
        canAddToStoryBox.value = await checkForValidStoryboxAsset(props.entity, props.entity.primary_mediafile)
        checkAssetIsInAStorybox()
      }
    )

    const checkForValidStoryboxAsset = async (_entity: typeof Entity, _filename: string | undefined): Promise<boolean> => {
      const checks: Array<boolean> = []
      useStoryboxFeature.value === true && userStore.hasUser && props.entity ? checks.push(true) : checks.push(false)
      if (!checks.includes(false)) {
        const mediaTypeForPrimaryFile = await getMediaTypeByfilename(props.entity, props.entity.primary_mediafile)
        mediaTypeForPrimaryFile !== null && mediaTypeForPrimaryFile.image && mediaTypeForPrimaryFile.image === true ? checks.push(true) : checks.push(false)
      }
      return !checks.some((_check) => _check === false)
    }

    const copyUrl = async (id: String) => {
      try {
        var url = window.location.href
        await toClipboard(url)
        isCopied.value = true
      } catch (e) {
        console.error(e)
      }
    }

    onBeforeRouteUpdate(() => {
      isCopied.value = false
    })

    return {
      assetIsInAStorybox,
      storyboxDdOpen,
      canAddToStoryBox,
      addAssetToStorybox,
      copyUrl,
      userStore,
      t,
      isCopied,
    }
  },
})
