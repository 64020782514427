
import { defineComponent, ref } from 'vue'
import { BaseModal, ModalState } from 'coghent-vue-3-component-library'
import { useI18n } from 'vue-i18n'
import { getCookie, setCookie } from 'tiny-cookie'

export type DisclaimerModalType = {
  state: typeof ModalState
}

const DisclaimerModalState = ref<DisclaimerModalType>({
  state: 'hide',
})

export const useDisclaimerModal = () => {
  const updateDisclaimerModal = (DisclaimerModalInput: DisclaimerModalType) => {
    DisclaimerModalState.value = DisclaimerModalInput
  }

  const closeDisclaimerModal = () => {
    updateDisclaimerModal({
      state: 'hide',
    })
    setCookie('disclaimer', 'seen', { expires: 7 })
  }

  const openDisclaimerModal = () => {
    updateDisclaimerModal({
      state: 'show',
    })
  }

  const smallDisclaimerModal = () => {
    updateDisclaimerModal({
      state: 'small',
    })
  }

  return {
    smallDisclaimerModal,
    closeDisclaimerModal,
    openDisclaimerModal,
    DisclaimerModalState,
  }
}

export default defineComponent({
  components: {
    BaseModal,
  },
  setup() {
    const { closeDisclaimerModal, DisclaimerModalState, openDisclaimerModal, smallDisclaimerModal } = useDisclaimerModal()
    const { t } = useI18n()

    const cookie = getCookie('disclaimer')

    if (!cookie) {
      smallDisclaimerModal()
    }

    return {
      closeDisclaimerModal,
      DisclaimerModalState,
      openDisclaimerModal,
      t,
    }
  },
})
