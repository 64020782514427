import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock(_component_VDropdown, null, {
    popper: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          to: `/mijnverhalen/${_ctx.storyBoxInfo.id}`,
          target: "_blank"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseButton, {
              "custom-style": "storybox-black",
              text: _ctx.t('storybox.edit'),
              "custom-icon": "edit",
              "icon-shown": true
            }, null, 8, ["text"])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createVNode(_component_BaseButton, {
          "custom-style": "storybox-black",
          text: _ctx.t('storybox.showQR'),
          "custom-icon": "qrCode",
          "icon-shown": true,
          onClick: _ctx.openQR
        }, null, 8, ["text", "onClick"]), [
          [_directive_close_popper]
        ])
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createVNode(_component_BaseButton, {
          "custom-style": "storybox-red",
          text: _ctx.t('storybox.delete'),
          "custom-icon": "wasteBasket",
          "icon-shown": true,
          onClick: _ctx.handleConfirmationModal
        }, null, 8, ["text", "onClick"]), [
          [_directive_close_popper]
        ])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}