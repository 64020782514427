import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e49a63e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "h-full w-full flex"
}
const _hoisted_2 = { class: "font-bold mb-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    large: true,
    modalState: "show",
    onHideModal: _ctx.closeOnBoarding
  }, {
    default: _withCtx(() => [
      (_ctx.carouselState.carouselContent)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createElementVNode("div", {
              class: "w-1/12 h-full cursor-pointer absolute top-0 left-0 left-fade z-20 flex justify-center items-center",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousCarouselSlide && _ctx.previousCarouselSlide(...args)))
            }, [
              _createVNode(_component_BaseIcon, {
                icon: "arrowLeftLine",
                height: 24,
                width: 24,
                class: "stroke-black"
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_TransitionGroup, { name: _ctx.animationDirection }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carouselState.carouselContent, (content, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      class: "flex justify-center",
                      key: index
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(`absolute ${content.descriptionLocation} p-4 bg-white-see-trough max-w-md z-40`)
                      }, [
                        _createElementVNode("h3", _hoisted_2, _toDisplayString(content.contentName), 1),
                        _createElementVNode("p", {
                          innerHTML: _ctx.t(content.contentDescription)
                        }, null, 8, _hoisted_3)
                      ], 2),
                      _createElementVNode("img", {
                        src: content.contentLocation,
                        alt: content.contentName,
                        class: "max-h-full"
                      }, null, 8, _hoisted_4)
                    ])), [
                      [_vShow, index + 1 === _ctx.carouselState.currentCarouselSlide]
                    ])
                  }), 128))
                ]),
                _: 1
              }, 8, ["name"])
            ]),
            _createElementVNode("div", {
              class: "w-1/12 h-full cursor-pointer absolute top-0 right-0 right-fade z-20 flex justify-center items-center",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextCarouselSlide && _ctx.nextCarouselSlide(...args)))
            }, [
              _createVNode(_component_BaseIcon, {
                icon: "arrowRightLine",
                height: 24,
                width: 24,
                class: "stroke-black"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onHideModal"]))
}