
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import { GetEntityByIdDocument } from 'coghent-vue-3-component-library'
import TheGrid from './TheGrid.vue'
import { useI18n } from 'vue-i18n'

const asString = (x: string | string[]) => (Array.isArray(x) ? x[0] : x)

export default defineComponent({
  name: 'RelationDetail',
  components: {
    TheGrid,
  },
  setup: () => {
    const id = asString(useRoute().params['relationID'])
    const { result } = useQuery(GetEntityByIdDocument, { id })
    const { t } = useI18n()

    return {
      id,
      result,
      t,
    }
  },
})
