import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "text-xl mb-6" }
const _hoisted_3 = { class: "flex justify-evenly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    customZIndex: "z-50",
    "modal-state": _ctx.ConfirmationModalState.state,
    onHideModal: _ctx.closeConfirmationModal,
    showCloseButton: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.modalText), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseButton, {
            customStyle: "ghost-black",
            text: _ctx.declineButtonText,
            iconShown: false,
            onClick: _ctx.closeConfirmationModal
          }, null, 8, ["text", "onClick"]),
          _createVNode(_component_BaseButton, {
            customStyle: "ghost-red",
            text: _ctx.confirmButtonText,
            iconShown: false,
            onClick: _ctx.handleCallback
          }, null, 8, ["text", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modal-state", "onHideModal"]))
}