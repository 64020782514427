
import { useUpload } from 'coghent-vue-3-component-library'
import { Rights } from 'coghent-vue-3-component-library'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const styleButton = {
  selected: `bg-accent-purple`,
  notSelected: `bg-text-white`,
  notSelectedRing: `bg-neutral-40 opacity-40 `,
}

type UploadOption = {
  selected: boolean
  title: string
  info: string
  disclaimer: string
  disclaimerPart2: string
  license: typeof Rights
}

export default defineComponent({
  name: 'UploadStepTwo',
  components: {},
  setup() {
    const { t } = useI18n()
    const options = ref<Array<UploadOption>>([])
    const { setCreator, rightIsSet, setAgreedToDisclaimer } = useUpload()
    const disclaimerPart1CheckboxState = ref<boolean>(false)
    const disclaimerPart2CheckboxState = ref<boolean>(false)
    const disclaimerCheckboxState = computed<boolean>(() => {
      if (disclaimerPart1CheckboxState.value && disclaimerPart2CheckboxState.value) {
        return true
      } else {
        return false
      }
    })

    const setOptions = () => {
      options.value.push({
        selected: rightIsSet(Rights.Cc0),
        title: `${t(`myWorks.upload.stepTwo.options.owner.title`)}`,
        info: `${t(`myWorks.upload.stepTwo.options.owner.info`)}`,
        disclaimer: `${t(`myWorks.upload.stepTwo.options.owner.disclaimer`)}`,
        disclaimerPart2: `${t(`myWorks.upload.stepTwo.options.disclaimerPart2`)}`,
        license: Rights.Cc0,
      } as UploadOption)
      options.value.push({
        selected: rightIsSet(Rights.Undetermined),
        title: `${t(`myWorks.upload.stepTwo.options.uploader.title`)}`,
        info: `${t(`myWorks.upload.stepTwo.options.uploader.info`)}`,
        disclaimer: `${t(`myWorks.upload.stepTwo.options.uploader.disclaimer`)}`,
        disclaimerPart2: `${t(`myWorks.upload.stepTwo.options.disclaimerPart2`)}`,
        license: Rights.Undetermined,
      } as UploadOption)
    }

    const setSelectedOption = (_option: UploadOption) => {
      for (const [index, option] of options.value.entries()) {
        _option.title === option.title ? (options.value[index].selected = true) : (options.value[index].selected = false)
      }
      if (disclaimerCheckboxState.value) {
        setCreator(_option.license)
      }
      disclaimerPart1CheckboxState.value = false
      disclaimerPart2CheckboxState.value = false
    }

    watch(
      () => disclaimerCheckboxState.value,
      () => {
        setAgreedToDisclaimer(disclaimerCheckboxState.value)
      },
      { immediate: true }
    )

    setOptions()

    return {
      t,
      setSelectedOption,
      options,
      styleButton,
      disclaimerPart1CheckboxState,
      disclaimerPart2CheckboxState,
    }
  },
})
