
import { defineComponent, ref, watch } from 'vue'
import { UserStore } from '../stores/UserStore'
import { User } from 'coghent-vue-3-component-library'
import StoreFactory from '../stores/StoreFactory'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useSessionAuth } from '@/app'
import { BaseButton } from 'coghent-vue-3-component-library'
import useMenu, { MenuItem } from '@/composables/useMenu'

export default defineComponent({
  name: 'ProfileSideMenu',
  components: { BaseButton },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const userStore = StoreFactory.get(UserStore)
    const user: typeof User = userStore.user
    const { profileSideMenuItems } = useMenu()
    const pages = ref<Array<MenuItem>>(profileSideMenuItems.filter((_item) => _item.isVisible === true))

    const { t } = useI18n()

    const changeActivePage = (pageIndex: number) => {
      pages.value.forEach((page: MenuItem) => (page.linkIsActive = false))
      pages.value[pageIndex].linkIsActive = true
    }

    watch(
      () => route.path,
      (path) => {
        const activePage: MenuItem | undefined = pages.value.find((page: MenuItem) => page.link == path)
        if (activePage) {
          const activePageIndex: number = pages.value.indexOf(activePage)
          pages.value[activePageIndex].linkIsActive = true
        }
      },
      { immediate: true }
    )

    const logout = async () => {
      fetch('/api/logout')
        .then(async (response) => {
          userStore.setUser(null)
          useSessionAuth.resetAuthProperties()
          router.push('/')
        })
        .catch((error) => {
          router.push('/')
          console.log(`WEB | Couldn't logout`, { error })
        })
    }

    return {
      changeActivePage,
      pages,
      logout,
      user,
      t,
    }
  },
})
