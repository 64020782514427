import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "md:text-5xl sm:text-4xl text-3xl font-bold w-8/12 text-center py-10 block leading-normal" }
const _hoisted_3 = { class: "text-accent-purple" }
const _hoisted_4 = { class: "col-span-2" }
const _hoisted_5 = { class: "font-bold text-2xl w-full text-center pt-10 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_grid = _resolveComponent("the-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.t('details.notFound.title')) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('details.notFound.not')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.t('details.notFound.found')), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t('details.notFound.other')), 1),
      _createVNode(_component_the_grid, {
        small: true,
        "no-header": true
      })
    ])
  ], 64))
}