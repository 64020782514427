import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "h-full pt-6 px-6 flex text-center flex-col items-center" }
const _hoisted_2 = { class: "flex flex-wrap font-bold text-4xl pb-4" }
const _hoisted_3 = { class: "h-full w-full pb-4 grid grid-cols-1 lg:grid-cols-2 lg:gap-4 overflow-y-scroll" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "h-full flex justify-center items-center" }
const _hoisted_6 = { class: "h-fit" }
const _hoisted_7 = { class: "font-bold text-lg mt-2" }
const _hoisted_8 = { class: "font-normal text-base mt-6" }
const _hoisted_9 = { class: "flex flex-col mt-14" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = ["id", "name"]
const _hoisted_12 = ["for", "innerHTML"]
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = ["id", "name"]
const _hoisted_15 = ["for", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('myWorks.upload.stepTwo.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          key: option.title,
          class: _normalizeClass([[option.selected === true ? 'border-2 border-accent-purple' : ''], "my-6 bg-text-white p-4 flex flex-col text-center shadow-md cursor-pointer"]),
          onClick: () => option.selected !== true && _ctx.setSelectedOption(option)
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["rounded-full w-20 h-20 z-10 flex justify-center items-center cursor-pointer", [option.selected ? _ctx.styleButton.selected : _ctx.styleButton.notSelectedRing]])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["rounded-full bg-text-white w-16 h-16 flex justify-center items-center", [option.selected ? _ctx.styleButton.selected : _ctx.styleButton.notSelected]])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["rounded-full w-14 h-14", [option.selected ? _ctx.styleButton.selected : _ctx.styleButton.notSelected]])
                }, null, 2)
              ], 2)
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h2", _hoisted_7, _toDisplayString(option.title), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(option.info), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  id: option.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.disclaimerPart1CheckboxState) = $event)),
                  class: "mx-2",
                  type: "checkbox",
                  name: option.title
                }, null, 8, _hoisted_11), [
                  [_vShow, option.selected],
                  [_vModelCheckbox, _ctx.disclaimerPart1CheckboxState]
                ]),
                _createElementVNode("label", {
                  for: option.title,
                  class: "font-normal text-sm italic cursor-pointer",
                  innerHTML: option.disclaimer
                }, null, 8, _hoisted_12)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  id: `${option.title}-option-2`,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.disclaimerPart2CheckboxState) = $event)),
                  class: "mx-2",
                  type: "checkbox",
                  name: `${option.title}-option-2`
                }, null, 8, _hoisted_14), [
                  [_vShow, option.selected],
                  [_vModelCheckbox, _ctx.disclaimerPart2CheckboxState]
                ]),
                _createElementVNode("label", {
                  for: `${option.title}-option-2`,
                  class: "font-normal text-sm italic mt-2 cursor-pointer",
                  innerHTML: option.disclaimerPart2
                }, null, 8, _hoisted_15)
              ])
            ])
          ])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}