
import { defineComponent, ref, watch } from 'vue'
import { Entity, KeyValuePair, entityIsPublic, StoryBoxState, BaseIcon, BaseDropDown } from 'coghent-vue-3-component-library'
import { useI18n } from 'vue-i18n'
import { router } from '@/app'
import InputLabel from './InputLabel.vue'
import { useCustomStorySubtitles } from '../stores/ConfigStore'

export default defineComponent({
  components: { BaseIcon, BaseDropDown, InputLabel },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const startDragItem = ref<string>('')
    const canDrag = ref<boolean>(false)
    const assets = ref<Array<typeof Entity>>([])
    const assetTimings = ref<Array<typeof Entity>>(StoryBoxState.value.activeStorybox.assetTimings)
    const assetDescriptions = ref<typeof KeyValuePair[]>(StoryBoxState.value.activeStorybox.assetDescriptions)
    const draggingAssetComesBelow = ref<string | null>(null)
    const assetTimingPresent = ref<boolean>(false)

    const fallBackDeleteAsset = () => {
      if (assets.value === undefined || assets.value.length <= 0) {
        console.log(`Using fallback to reset the assets use in the component to the activestorybox assets`)
        assets.value = StoryBoxState.value.activeStorybox.assets
      }
    }

    const deleteAsset = async (_asset: typeof Entity) => {
      fallBackDeleteAsset()
      const index = assets.value.map((asset) => asset.id === _asset.id).indexOf(true)
      if (index !== -1) {
        StoryBoxState.value.activeStorybox.assets = deleteFromArray(assets.value, index)
        StoryBoxState.value.activeStorybox.assets = assets.value
      } else {
        StoryBoxState.value.activeStorybox.assets = assets.value
      }
    }

    const deleteFromArray = (_array: Array<any>, _index: number) => {
      let arrayToUpdate = _array
      if (_index != -1) {
        arrayToUpdate.splice(_index, 1)
      }
      return arrayToUpdate
    }

    const updateDescription = (event: any) => {
      StoryBoxState.value.activeStorybox.description = event.target.value
    }

    const dragStart = (event: any) => {
      startDragItem.value = event.srcElement.id
      document.getElementById(event.srcElement.id)?.parentElement?.classList.add(`test`)
      document.getElementById(event.srcElement.id)?.firstElementChild?.classList.replace('bg-background-light', 'bg-background-dark')
      document.getElementById(event.srcElement.id)?.firstElementChild?.classList.add('test')
    }
    const dragEnd = (event: any) => {
      canDrag.value = false
      startDragItem.value = event.srcElement.id
      document.getElementById(event.srcElement.id)?.parentElement?.classList.remove(`test`)
      document.getElementById(event.srcElement.id)?.firstElementChild?.classList.replace('bg-background-dark', 'bg-background-light')
      document.getElementById(event.srcElement.id)?.parentElement?.classList.remove(`test`)
      StoryBoxState.value.activeStorybox.assets = assets.value
    }

    const dragEnter = (event: any) => {
      event.preventDefault()
      swap(StoryBoxState.value.activeStorybox.assets, startDragItem.value, event.srcElement.id)
    }

    const swap = async (_assets: Array<typeof Entity>, itemOne: string, itemTwo: string) => {
      const assetIndexOne = _assets.indexOf(_assets[_assets.map((asset) => asset.id === itemOne).indexOf(true)])
      const assetOne = _assets[_assets.map((asset) => asset.id === itemOne).indexOf(true)]
      const assetIndexTwo = _assets.indexOf(_assets[_assets.map((asset) => asset.id === itemTwo).indexOf(true)])
      const assetTwo = _assets[_assets.map((asset) => asset.id === itemTwo).indexOf(true)]
      assetIndexTwo != -1 ? (draggingAssetComesBelow.value = assetTwo.id) : (draggingAssetComesBelow.value = null)
      if (canDrag.value === true && assetOne && assetTwo && assetIndexOne != assetIndexTwo) {
        let updatedAssets: Array<typeof Entity> = []
        Object.assign(updatedAssets, _assets)
        updatedAssets = deleteItemFromArray(assetOne, updatedAssets)
        updatedAssets = placeItemOnIndexMoveOthers(assetTwo, assetOne, updatedAssets)
        assets.value = updatedAssets
      }
    }

    const deleteItemFromArray = (_item: typeof Entity, _items: Array<typeof Entity>) => {
      let updatedItems: Array<typeof Entity> = []
      Object.assign(updatedItems, _items)
      const index = updatedItems.map((asset) => asset.id === _item.id).indexOf(true)
      index !== -1 ? updatedItems.splice(index, 1) : null
      return updatedItems
    }

    const placeItemOnIndexMoveOthers = (_itemTwo: typeof Entity, _item: typeof Entity, _items: Array<typeof Entity>) => {
      const index = _items.map((asset) => asset.id === _itemTwo.id).indexOf(true)
      let updatedItems: Array<typeof Entity> = []
      if (index != -1) {
        const itemsbefore = _items.slice(0, index + 1)
        updatedItems.push(...itemsbefore)
        updatedItems.push(_item)
        const itemsafter = _items.slice(index + 1, _items.length)
        updatedItems.push(...itemsafter)
      }
      return updatedItems
    }

    const setAssetProperty = (_asset: typeof Entity, _property: 'timing' | 'description') => {
      let returnValue = null
      const propertyToChange = _property === 'timing' ? StoryBoxState.value.activeStorybox.assetTimings : StoryBoxState.value.activeStorybox.assetDescriptions
      if (propertyToChange) {
        for (const _pair of propertyToChange) {
          if (_pair.key === _asset.id) returnValue = _pair.value
        }
      }
      return returnValue
    }

    const updateAssetProperty = (_asset: typeof Entity, _propertyValue: number | string, _propertyName: 'timing' | 'description') => {
      const propertyToChange = _propertyName === 'timing' ? StoryBoxState.value.activeStorybox.assetTimings : StoryBoxState.value.activeStorybox.assetDescriptions
      for (const _pair of propertyToChange) {
        if (_pair.key === _asset.id) {
          _pair.value = _propertyName === 'timing' ? _propertyValue : (document.getElementById(`description-${_asset.id}`) as HTMLTextAreaElement).value
        }
      }
    }

    watch(
      () => StoryBoxState.value.activeStorybox.assetTimings,
      (timings) => {
        StoryBoxState.value.activeStorybox.assetTimings !== undefined ? (assetTimingPresent.value = true) : null
      }
    )

    watch(
      () => props.loading,
      (isLoading) => {
        if (isLoading === false) {
          assets.value = StoryBoxState.value.activeStorybox.assets
        }
      }
    )

    return {
      t,
      updateDescription,
      deleteAsset,
      router,
      dragStart,
      dragEnd,
      dragEnter,
      canDrag,
      setAssetProperty,
      updateAssetProperty,
      StoryBoxState,
      assets,
      assetTimings,
      draggingAssetComesBelow,
      entityIsPublic,
      assetTimingPresent,
      assetDescriptions,
      useCustomStorySubtitles,
    }
  },
})
