
import { useUpload, CircleLoader } from 'coghent-vue-3-component-library'
import { ModalState, BaseButton, BaseModal, currentUploadStep, StepProgress } from 'coghent-vue-3-component-library'
import { defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import UploadStepOne from '@/components/UploadStepOne.vue'
import UploadStepTwo from '@/components/UploadStepTwo.vue'
import UploadStepThree from '@/components/UploadStepThree.vue'
import UploadStepFour from '@/components/UploadStepFour.vue'
import UploadDone from '@/components/UploadDone.vue'
import StoreFactory from '@/stores/StoreFactory'
import { UserStore } from '@/stores/UserStore'
import { apolloClient, router } from '@/app'
import { uploadState } from 'coghent-vue-3-component-library'
import { UploadStatus } from 'coghent-vue-3-component-library'
import uploadWizard from '@/composables/uploadWizard'
import { getUrlParamValue } from 'coghent-vue-3-component-library'
import { uploadLoadingState } from 'coghent-vue-3-component-library'

const useModal = () => {
  const modalState = ref<typeof ModalState>(`hide`)
  const openCloseUpload = (_state: typeof ModalState) => (modalState.value = _state)

  return { modalState, openCloseUpload }
}

export default defineComponent({
  components: {
    BaseModal,
    BaseButton,
    UploadStepOne,
    UploadStepTwo,
    UploadStepThree,
    UploadStepFour,
    UploadDone,
    StepProgress,
    CircleLoader,
  },
  setup() {
    const { modalState, openCloseUpload } = useModal()
    const { newInit, nextStep, previousStep, setStatus, upload, setStep, entityToUploadComposable, setUploadState, updateAsset } = useUpload()
    const { t } = useI18n()
    const userStore = StoreFactory.get(UserStore)
    const showPrevious = ref<'visible' | 'invisible'>(`invisible`)
    const steps = ref<Array<string>>([])
    const stepDone = ref<boolean>(false)
    const { TOTAL_STEPS, ASSET_ID_PARAM, getActionValues, canShowStep, assetId, isModeEdit, isModeUploadNew, showPreviousButton } = uploadWizard()

    watch(modalState, (state: string) => {
      state === 'show' ? document.body.classList.add('overflow-y-hidden') : null
      state === 'hide' ? document.body.classList.remove('overflow-y-hidden') : null
    })

    watch(currentUploadStep, async (_step: number) => {
      showPreviousButton(_step) ? (showPrevious.value = 'visible') : (showPrevious.value = 'invisible')
      console.log(`show previous`, showPrevious.value)
      if (_step === 5) {
        isModeUploadNew.value === true ? await upload(apolloClient) : null
        isModeEdit.value === true ? await updateAsset(assetId.value, 'updated', apolloClient) : null
        nextStep()
      }
    })

    watch(
      () => uploadLoadingState.actionValues,
      (actionValuesState) => {
        actionValuesState === 'loaded' ? (stepDone.value = true) : null
      }
    )

    watch(
      () => [uploadState.agreedToDisclaimer, currentUploadStep.value],
      () => {
        if (currentUploadStep.value === 2) {
          uploadState.agreedToDisclaimer ? (stepDone.value = true) : (stepDone.value = false)
        }
      },
      { immediate: true }
    )

    const setSteps = () => {
      steps.value.push(`${t(`myWorks.upload.steps.stepOne`)}`)
      steps.value.push(`${t(`myWorks.upload.steps.stepTwo`)}`)
      steps.value.push(`${t(`myWorks.upload.steps.stepThree`)}`)
      steps.value.push(`${t(`myWorks.upload.steps.stepFour`)}`)
    }

    const closeWizard = () => {
      setStatus(UploadStatus.Waiting)
      openCloseUpload('hide')
      router.push(`mijnobjecten`)
    }

    const prepareWizardData = () => {
      setSteps()
      getActionValues(getUrlParamValue(ASSET_ID_PARAM), setStep, entityToUploadComposable)
    }

    const showLoader = () => {
      const statusses = [uploadLoadingState.actionValues === 'loading', uploadLoadingState.upload === 'loading', uploadLoadingState.update === 'loading']
      return statusses.some((status) => status === true)
    }

    const init = () => {
      if (userStore.hasUser) {
        newInit(userStore.user.value.email)
        openCloseUpload(`show`)
        prepareWizardData()
      } else router.go(-1)
    }

    init()

    return {
      t,
      modalState,
      openCloseUpload,
      showPrevious,
      currentUploadStep,
      nextStep,
      previousStep,
      steps,
      uploadState,
      closeWizard,
      stepDone,
      TOTAL_STEPS,
      canShowStep,
      isModeEdit,
      isModeUploadNew,
      showPreviousButton,
      uploadLoadingState,
      showLoader,
    }
  },
})
