
import { apolloClient, iiif, router } from '@/app'
import ProfileListItem, { ProfileListItemInfo, ProfileListItemType } from '@/components/ProfileListItem.vue'
import uploadWizard from '@/composables/uploadWizard'
import { useUpload, CircleLoader, BaseIcon } from 'coghent-vue-3-component-library'
import { getMetadataOfTypeFromEntity } from 'coghent-vue-3-component-library'
import { Entity } from 'coghent-vue-3-component-library'
import { BaseButton } from 'coghent-vue-3-component-library'
import { defineComponent, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ProfileSideMenu from '../components/ProfileSideMenu.vue'
import ConfirmationModal from '../components/ConfirmationModal.vue'
import { Pager } from '../composables/pager'

export default defineComponent({
  name: 'TheWorksPage',
  components: {
    ProfileSideMenu,
    ProfileListItem,
    BaseButton,
    CircleLoader,
    BaseIcon,
    ConfirmationModal,
  },
  setup() {
    const { t } = useI18n()
    const uploadRoute = `/upload`
    const myWorks = ref<Array<ProfileListItemInfo>>([])
    const isLoading = ref<boolean>(false)
    const { getUploads, stripUserUploadPrefix, getMediafiles, getMediafileLink, getFilename, updateAsset } = useUpload()
    const { ASSET_ID_PARAM } = uploadWizard()
    const { generateUrl } = iiif
    const pager = new Pager(6)

    const prepareCards = async (_entities: Array<typeof Entity> | null) => {
      if (_entities !== null) {
        const worksToDisplay = []
        for (const asset of _entities) {
          const mediafiles = await getMediafiles(asset)
          let title = getMetadataOfTypeFromEntity(asset, 'title')
          let maker = getMetadataOfTypeFromEntity(asset, 'maker')
          let publicationStatus = getMetadataOfTypeFromEntity(asset, 'publication_status')
          let userAction = getMetadataOfTypeFromEntity(asset, 'user_action')
          worksToDisplay.push({
            id: asset.id,
            title: title ? title.value : 'Title placeholder',
            description: maker ? maker.value : 'Onbekend',
            // dateCreated: '24 februari 2020',
            onClickUrl: '/entity/' + asset.id,
            pictureUrl: getMediafileLink(mediafiles),
            // pictureUrl: generateUrl(getFilename(mediafiles[0] ? mediafiles[0] : null), 'full', 48),
            status: publicationStatus ? publicationStatus.value : null,
            type: ProfileListItemType.uploadedWork,
            entity: asset,
            action: userAction ? userAction.value : 'updated',
          } as ProfileListItemInfo)
        }
        myWorks.value = worksToDisplay
      }
    }

    const fetchData = async () => {
      myWorks.value = []
      isLoading.value = true
      const entitiesResults = await getUploads(apolloClient, pager.limit, pager.skip)
      if (entitiesResults !== null) {
        pager.updateCount(entitiesResults.count)
        await prepareCards(entitiesResults.results)
      } else {
        myWorks.value = []
      }
      isLoading.value = false
    }

    const init = async () => {
      fetchData()
    }

    init()

    return {
      myWorks,
      t,
      router,
      uploadRoute,
      isLoading,
      pager,
      fetchData,
    }
  },
})
