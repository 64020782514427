import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row items-stretch bg-background-light justify-evenly p-2 w-full" }
const _hoisted_2 = { class: "mx-3 align-center" }
const _hoisted_3 = {
  key: 0,
  class: "hidden border-r-2 h-6 border-text-dark border-opacity-70 mx-6 hidden"
}
const _hoisted_4 = {
  key: 0,
  class: "border-r-2 h-auto border-background-dark border-opacity-70 mr-2"
}
const _hoisted_5 = {
  key: 1,
  class: "mx-3 align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_button = _resolveComponent("base-button")!
  const _component_AddAssetToStoryboxDropdown = _resolveComponent("AddAssetToStoryboxDropdown")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_button, {
        class: "w-12 h-12 stroke-current text-text-black inline-block lg:hidden",
        "on-click": () => _ctx.copyUrl(_ctx.entity.id),
        "custom-style": "secondary-round",
        "custom-icon": !_ctx.isCopied ? 'link' : 'check',
        "icon-shown": true
      }, null, 8, ["on-click", "custom-icon"]),
      _createVNode(_component_base_button, {
        class: "w-max hidden lg:flex",
        text: _ctx.t('details.modal.link'),
        "on-click": () => _ctx.copyUrl(_ctx.entity.id),
        "custom-style": "ghost-black",
        "custom-icon": !_ctx.isCopied ? 'link' : 'check',
        "icon-shown": true
      }, null, 8, ["text", "on-click", "custom-icon"]),
      (_ctx.userStore.hasUser)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createVNode(_component_base_button, {
        class: "hidden w-12 h-12 stroke-current text-text-black inline-block lg:hidden",
        "on-click": _ctx.onClick,
        "custom-style": "secondary-round",
        "custom-icon": "edit",
        "icon-shown": true
      }, null, 8, ["on-click"]),
      _createVNode(_component_base_button, {
        class: "hidden w-max hidden",
        text: _ctx.t('details.modal.edit'),
        "on-click": _ctx.onClick,
        "custom-style": "ghost-black",
        "custom-icon": "edit",
        "icon-shown": true
      }, null, 8, ["text", "on-click"])
    ]),
    (_ctx.canAddToStoryBox === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.canAddToStoryBox === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_AddAssetToStoryboxDropdown, {
            trigger: _ctx.storyboxDdOpen,
            entity: _ctx.entity,
            onClick: _cache[0] || (_cache[0] = () => (_ctx.storyboxDdOpen = !_ctx.storyboxDdOpen)),
            onAddToStorybox: _cache[1] || (_cache[1] = (ids) => _ctx.addAssetToStorybox(ids))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_button, {
                text: _ctx.t('buttons.addToStorybox'),
                "custom-style": "ghost-purple",
                "icon-shown": true,
                "custom-icon": _ctx.assetIsInAStorybox ? `check` : `storybox`,
                class: "px-2 hidden lg:flex"
              }, null, 8, ["text", "custom-icon"]),
              _createVNode(_component_base_button, {
                "custom-style": "secondary-round",
                "icon-shown": true,
                "custom-icon": _ctx.assetIsInAStorybox ? `check` : `storybox`,
                class: "w-12 h-12 stroke-current text-accent-purple inline-block lg:hidden"
              }, null, 8, ["custom-icon"])
            ]),
            _: 1
          }, 8, ["trigger", "entity"])
        ]))
      : _createCommentVNode("", true)
  ]))
}