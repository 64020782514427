import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap flex-row w-full justify-center mb-5"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-wrap flex-row w-full justify-center mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterTag = _resolveComponent("FilterTag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loading && _ctx.filters.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_FilterTag, {
            "is-selected": _ctx.selected.length === 0,
            filter: _ctx.filterAll,
            icon: 'check',
            onClick: _ctx.toggleFilters
          }, null, 8, ["is-selected", "filter", "onClick"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersWithLabel, (tag) => {
            return (_openBlock(), _createBlock(_component_FilterTag, {
              key: tag,
              "is-selected": _ctx.isTagSelected(tag.key),
              filter: tag.value,
              icon: 'check',
              onClick: ($event: any) => (_ctx.addToSelectedTags(tag.key))
            }, null, 8, ["is-selected", "filter", "onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
            return _createVNode(_component_FilterTag, {
              key: index,
              filter: "                    ",
              icon: 'check'
            })
          }), 64))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}