
import { defineComponent, watch, ref, reactive, PropType, computed, toRaw, onMounted } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { BaseSearch, GetEntitiesDocument, GetEntitiesQuery, GetEntitiesQueryVariables, BaseButton, TheMasonry } from 'coghent-vue-3-component-library'
import { Entity, Maybe, Relation, Scalars, Story } from 'coghent-vue-3-component-library'
import 'coghent-vue-3-component-library/lib/index.css'
import { useI18n } from 'vue-i18n'
import Filter from './Filter.vue'
// import { Entity, Maybe, Relation, Scalars, Story } from 'coghent-vue-3-component-library/lib/queries'
import useSeed from '../composables/useSeed'
import { useActiveBox } from '@/composables/useActiveBox'
import { useHistory } from './BreadCrumbs.vue'
import { useRouter, useRoute } from 'vue-router'
import useClipboard from 'vue-clipboard3'
import { iiif, storyboxCount } from '@/app'
import { useOnScreenKeyboard, keyboard } from '../composables/useOnScreenKeyboard'

export default defineComponent({
  name: 'AssetGrid',
  components: { BaseSearch, TheMasonry, BaseButton, Filter },
  data: () => ({
    input: '',
  }),
  props: {
    defaultSearchQuery: {
      type: String,
      default: '',
      required: false,
    },
    defaultRelations: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    small: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noFilters: {
      type: Boolean,
      default: false,
    },
    noRelations: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const selectedFilters = ref<string[]>([])
    const searchQueryForInput = ref<string>('')
    const searchQueryForQuery = ref<string>(props.defaultSearchQuery)
    let _skip: number = 0
    const limit: number = route.query.touch ? 50 : 25
    const endOfData = ref<Boolean>(false)
    const useAndFilter = ref<Boolean>(false)
    const entityData = ref<typeof GetEntitiesQuery | undefined>()
    const relationData = ref<typeof Relation[] | undefined>([])
    const emptySearch = ref<Boolean>(false)
    const masonry = ref<any>(null)
    const { randomValue, refresh: refreshSeed } = useSeed()
    const { generateUrl, generateInfoUrl, noImageUrl } = iiif
    const { clearHistory } = useHistory()
    const frameList = ref<string[]>([])
    const queryEnabled = ref<boolean>(true)
    const { getActiveBox, activeBox } = useActiveBox()
    const { toClipboard } = useClipboard()
    const { showKeyboard, hideKeyboard } = useOnScreenKeyboard()

    const getSelectedFilters = computed<string[]>(() => {
      if (props.defaultRelations?.length > 0 && selectedFilters.value.length === 0) {
        return props.defaultRelations
      } else if (route.query.touch && !selectedFilters.value.length) {
        return frameList.value
      }
      return selectedFilters.value
    })

    onMounted(() => {
      if (route.query.touch) {
        queryEnabled.value = false
        getActiveBox().then((result) => {
          const frameIds: string[] = []
          if (result) {
            result.forEach((story: any) => {
              const storyFrameIds: string[] = story.frames.map((frame: typeof Entity) => 'entities/' + frame.id)
              frameIds.push(...storyFrameIds)
            })
            frameList.value.push(...frameIds)
            queryEnabled.value = true
            refetch()
          }
        })
      } else {
        refetch()
      }
    })

    const { result, loading, fetchMore, onResult, refetch } = useQuery<typeof GetEntitiesQuery, typeof GetEntitiesQueryVariables>(
      GetEntitiesDocument,

      () => ({
        limit: limit,
        skip: (_skip = 0),
        searchValue: {
          value: searchQueryForQuery.value,
          isAsc: false,
          relation_filter: getSelectedFilters.value,
          randomize: getSelectedFilters.value.length > 0 || searchQueryForQuery.value !== '' ? false : true,
          // seed: randomValue.value,
          key: 'title',
          has_mediafile: true,
          skip_relations: props.noRelations ? props.noRelations : false,
          and_filter: useAndFilter.value,
        },
      }),
      () => ({
        prefetch: false,
        enabled: queryEnabled.value,
      })
    )

    const isEndOfResult = (queryResult: typeof GetEntitiesQuery | undefined) => {
      if (queryResult) {
        if (queryResult && queryResult.Entities?.results && queryResult.Entities?.count && queryResult.Entities?.results.length >= queryResult.Entities?.count) {
          endOfData.value = true
        } else {
          endOfData.value = false
        }
      } else {
        endOfData.value = true
      }
    }

    onResult((queryResult) => {
      if (queryResult.data) {
        entityData.value = queryResult.data
        relationData.value = !router.currentRoute.value.params.entityID
          ? queryResult.data.Entities.relations
          : queryResult.data.Entities.relations.filter((relation: typeof Relation) => relation.type != 'isIn')
        isEndOfResult(queryResult.data)
      }
    })

    const getData = (forSearch: boolean = false) => {
      if (searchQueryForQuery.value !== searchQueryForInput.value) {
        if (masonry.value && masonry.value.contructTiles) {
          masonry.value.contructTiles(limit, true)
        }
        selectedFilters.value = []
        searchQueryForQuery.value = searchQueryForInput.value
      }

      if (searchQueryForQuery.value === '' && searchQueryForInput.value === '') {
        resetQuery()
      }
      if (forSearch) {
        frameList.value = []
      }
    }

    const resetQuery = () => {
      frameList.value = []
      refreshSeed()
      if (masonry.value && masonry.value.contructTiles) {
        masonry.value.contructTiles(limit, true)
      }
      selectedFilters.value = []
      if (searchQueryForQuery.value === '') {
        refetch()
      } else {
        searchQueryForInput.value = ''
        searchQueryForQuery.value = ''
      }
    }

    const updateSelectedFilters = (input: string[]) => {
      useAndFilter.value = input.length ? true : false
      if (masonry.value && masonry.value.contructTiles) {
        masonry.value.contructTiles(limit, true)
      }
      selectedFilters.value = input
    }

    const loadMore = () => {
      let newTiles = []
      if (masonry.value && masonry.value.contructTiles) {
        masonry.value.contructTiles(limit)
      }
      _skip = _skip! + limit
      fetchMore({
        variables: {
          skip: _skip,
        },
        updateQuery: (previousData, { fetchMoreResult }) => {
          if (fetchMoreResult?.Entities?.results?.length == 0) {
            endOfData.value = true
            return previousData
          }
          if (fetchMoreResult?.Entities && fetchMoreResult?.Entities?.results && fetchMoreResult?.Entities?.results?.length < limit) {
            endOfData.value = true
          }
          if (previousData.Entities && previousData.Entities.results && fetchMoreResult!.Entities?.results) {
            return {
              previousData,
              Entities: {
                ...previousData.Entities,
                results: [...previousData.Entities.results, ...fetchMoreResult!.Entities.results],
              },
            }
          } else {
            return previousData
          }
        },
      })
    }

    const copyUrl = async (id: string) => {
      try {
        var suffix = '/entity/' + id
        var splitted = window.location.href.substring(0, window.location.href.lastIndexOf('/'))
        var url = splitted + suffix
        await toClipboard(url)
      } catch (e) {
        console.error(e)
      }
    }

    document.addEventListener('virtualKeyboardEvent', (e) => {
      //@ts-ignore
      if (e.detail.input !== '{enter}') {
        //@ts-ignore
        searchQueryForInput.value = e.detail.input
      } else {
        frameList.value = []
        getData()
        hideKeyboard()
      }
    })

    return {
      t,
      limit,
      getData,
      loading,
      result,
      selectedFilters,
      updateSelectedFilters,
      searchQueryForQuery,
      searchQueryForInput,
      relationData,
      loadMore,
      endOfData,
      entityData,
      emptySearch,
      masonry,
      resetQuery,
      generateUrl,
      noImageUrl,
      clearHistory,
      route,
      copyUrl,
      showKeyboard,
      hideKeyboard,
    }
  },
})
