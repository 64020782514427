import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "m-8 h-full flex text-center items-center flex-col" }
const _hoisted_2 = { class: "flex flex-wrap font-bold text-4xl" }
const _hoisted_3 = { class: "w-full h-full grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6 py-8 overflow-y-scroll" }
const _hoisted_4 = { class: "bg-text-white py-4 px-4 lg:px-8 flex flex-col text-left shadow-md" }
const _hoisted_5 = { class: "font-bold text-lg mb-4 pb-4" }
const _hoisted_6 = { class: "pr-4 overflow-y-auto" }
const _hoisted_7 = ["for"]
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = { class: "bg-text-white py-4 px-8 flex flex-col text-left shadow-md" }
const _hoisted_10 = { class: "font-bold text-lg" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  key: 0,
  class: "px-2 max-h-80 overflow-y-scroll"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "mt-8 text-base font-normal" }
const _hoisted_15 = { class: "flex-grow flex my-4 p-4 flex flex-row flex-wrap bg-background-light gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('myWorks.upload.stepThree.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t('myWorks.upload.stepThree.metadata.title')), 1),
        _createElementVNode("span", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metadata, (question) => {
            return (_openBlock(), _createElementBlock("div", {
              key: question.text,
              class: "flex flex-col my-4",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateMetadata && _ctx.updateMetadata(...args)))
            }, [
              _createElementVNode("label", {
                class: "block text-base font-normal mb-2",
                for: question.text
              }, _toDisplayString(question.text), 9, _hoisted_7),
              _withDirectives((_openBlock(), _createElementBlock("input", {
                key: question.text,
                "onUpdate:modelValue": ($event: any) => ((question.answer) = $event),
                class: "bg-background-light appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                type: "text"
              }, null, 8, _hoisted_8)), [
                [_vModelText, question.answer]
              ])
            ], 32))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.t('myWorks.upload.stepThree.relation.title')), 1),
        _createElementVNode("span", null, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.relationSearch) = $event)),
            class: "mt-8 bg-background-light appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
            type: "text",
            placeholder: _ctx.t(`myWorks.upload.stepThree.relation.search`),
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updatedRelations && _ctx.updatedRelations(...args)))
          }, null, 40, _hoisted_11), [
            [_vModelText, _ctx.relationSearch]
          ]),
          (_ctx.dropdownResults.length >= 1 && _ctx.relationSearch.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownResults, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item,
                    class: "bg-background-medium text-left flex items-center h-8 px-4 py-1 hover:bg-background-dark",
                    onClick: ($event: any) => (_ctx.addToRelations(item))
                  }, _toDisplayString(item.value), 9, _hoisted_13))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.t(`myWorks.upload.stepThree.relation.relations`)), 1),
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relations, (relation) => {
            return (_openBlock(), _createElementBlock("div", {
              key: relation,
              class: "mr-2 bg-tag-neutral max-h-14 w-fit flex items-center px-2 py-1"
            }, [
              _createElementVNode("p", null, _toDisplayString(relation.value), 1),
              _createVNode(_component_BaseIcon, {
                icon: "close",
                class: "stroke-current ml-2 p-1 cursor-pointer",
                onClick: () => _ctx.removeFromRelations(relation)
              }, null, 8, ["onClick"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}