import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d3ffbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center justify-center w-full" }
const _hoisted_2 = { class: "flex items-center mt-12 flex-col-reverse md:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BoxVisitCode = _resolveComponent("BoxVisitCode")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseIcon, {
      class: "black p-12 opacity-50",
      icon: "link",
      width: 250,
      height: 250
    }),
    _createVNode(_component_BoxVisitCode, { code: _ctx.code }, null, 8, ["code"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CircleLoader, { class: "mt-4 md:mt-0" }),
      _createElementVNode("p", null, _toDisplayString(_ctx.t('visit.linkCodeToAccount')), 1)
    ])
  ]))
}