import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c2b08b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-between h-full bg-background-medium" }
const _hoisted_2 = { class: "h-4/5 pt-8" }
const _hoisted_3 = {
  key: 4,
  class: "h-full w-full flex justify-center items-center absolute z-20 top-0 left-0 bg-background-dark opacity-50"
}
const _hoisted_4 = { class: "z-10 grid grid-cols-1 grid-rows-2 gap-6 pb-6 lg:pb-0 lg:gap-0 block lg:flex lg:flex-rows lg:px-8 h-1/5 items-center bg-background-light" }
const _hoisted_5 = { class: "order-last mr-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadStepOne = _resolveComponent("UploadStepOne")!
  const _component_UploadStepTwo = _resolveComponent("UploadStepTwo")!
  const _component_UploadStepThree = _resolveComponent("UploadStepThree")!
  const _component_UploadStepFour = _resolveComponent("UploadStepFour")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_UploadDone = _resolveComponent("UploadDone")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_StepProgress = _resolveComponent("StepProgress")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    "modal-state": _ctx.modalState,
    large: true,
    class: "py-16 z-40",
    scroll: false,
    onHideModal: _cache[3] || (_cache[3] = () => _ctx.closeWizard())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.currentUploadStep === 1 && _ctx.canShowStep(1))
            ? (_openBlock(), _createBlock(_component_UploadStepOne, {
                key: 0,
                onStepDone: _cache[0] || (_cache[0] = (status) => (_ctx.stepDone = status))
              }))
            : _createCommentVNode("", true),
          (_ctx.currentUploadStep === 2 && _ctx.canShowStep(2))
            ? (_openBlock(), _createBlock(_component_UploadStepTwo, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.currentUploadStep === 3 && _ctx.canShowStep(3))
            ? (_openBlock(), _createBlock(_component_UploadStepThree, {
                key: 2,
                onUpdatedRelations: _cache[1] || (_cache[1] = (relations) => (_ctx.uploadState.relations = relations)),
                onUpdatedMetadata: _cache[2] || (_cache[2] = (metadata) => (_ctx.uploadState.metadata = metadata))
              }))
            : _createCommentVNode("", true),
          (_ctx.currentUploadStep === 4 || (_ctx.currentUploadStep === 5 && _ctx.canShowStep(4)))
            ? (_openBlock(), _createBlock(_component_UploadStepFour, { key: 3 }))
            : _createCommentVNode("", true),
          (_ctx.showLoader())
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_CircleLoader)
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentUploadStep === _ctx.TOTAL_STEPS && _ctx.canShowStep(_ctx.TOTAL_STEPS))
            ? (_openBlock(), _createBlock(_component_UploadDone, { key: 5 }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_base_button, {
            class: _normalizeClass([_ctx.showPrevious, "order-2 lg:order-first ml-6"]),
            "on-click": _ctx.previousStep,
            "icon-shown": false,
            "custom-style": "secondary",
            text: _ctx.t(`flow.previous`)
          }, null, 8, ["class", "on-click", "text"]),
          _createVNode(_component_StepProgress, {
            class: "order-1 lg:order-2 col-span-1",
            steps: _ctx.steps,
            "show-titles": true,
            "current-step": _ctx.currentUploadStep,
            "current-status": 'inProgress'
          }, null, 8, ["steps", "current-step"]),
          _createElementVNode("span", _hoisted_5, [
            (_ctx.currentUploadStep < 4)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 0,
                  class: "",
                  "on-click": () => (_ctx.stepDone === true ? _ctx.nextStep() : null),
                  "custom-style": _ctx.stepDone === true ? 'primary' : 'primaryUnavailable',
                  "icon-shown": false,
                  text: _ctx.t(`flow.next`)
                }, null, 8, ["on-click", "custom-style", "text"]))
              : _createCommentVNode("", true),
            (_ctx.currentUploadStep === 4 && _ctx.isModeUploadNew === true)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 1,
                  "on-click": _ctx.nextStep,
                  "icon-shown": false,
                  text: _ctx.t(`flow.upload`)
                }, null, 8, ["on-click", "text"]))
              : _createCommentVNode("", true),
            (_ctx.currentUploadStep === 4 && _ctx.isModeEdit === true)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 2,
                  "on-click": _ctx.nextStep,
                  "icon-shown": false,
                  text: _ctx.t(`flow.update`)
                }, null, 8, ["on-click", "text"]))
              : _createCommentVNode("", true),
            (_ctx.currentUploadStep === _ctx.TOTAL_STEPS)
              ? (_openBlock(), _createBlock(_component_base_button, {
                  key: 3,
                  "on-click": _ctx.closeWizard,
                  "icon-shown": false,
                  text: _ctx.t(`flow.close`)
                }, null, 8, ["on-click", "text"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modal-state"]))
}