
import uploadWizard, { MetadataQuestion } from '@/composables/uploadWizard'
import { uploadState, NO_IMAGE_PATH } from 'coghent-vue-3-component-library'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const { getMetadataWithQuestions } = uploadWizard()
    const metadata = ref<Array<MetadataQuestion>>(getMetadataWithQuestions())

    const setMediafile = () => {
      let imageSrc = NO_IMAGE_PATH
      if (uploadState.file !== null) imageSrc = uploadState.file
      if (uploadState.base64Image !== null) imageSrc = uploadState.base64Image
      return imageSrc
    }

    return {
      t,
      uploadState,
      setMediafile,
      metadata,
    }
  },
})
