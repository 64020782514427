import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 max-w-sm" }
const _hoisted_2 = {
  key: 0,
  class: "text-2xl font-bold text-accent-purple"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VDropdown = _resolveComponent("VDropdown")!

  return (_openBlock(), _createBlock(_component_VDropdown, {
    shown: _ctx.toolTipState.shown,
    "onUpdate:shown": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toolTipState.shown) = $event)),
    placement: _ctx.placement,
    triggers: _ctx.triggers,
    "auto-hide": ""
  }, {
    popper: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["shown", "placement", "triggers"]))
}