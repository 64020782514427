export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Collectie Van De Gentenaar"])},
  "main": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duik mee in de geschiedenis en ontdek de vele objecten uit onze"])},
    "rich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rijke collectie!"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bibliotheek"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ook iets op zolder gevonden? Laat het ons weten en <span class='text-accent-purple'>draag bij</span> aan de collectie!"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer laden"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoekopdracht gaf geen resultaten"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer info >"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal resultaten:"])},
    "tooltips": {
      "login": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bent nog niet aangemeld"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in om verder te gaan"])}
      }
    },
    "notifications": {
      "duplicateFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bestand werd als dubbel gedetecteerd"])},
      "wrongFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel bestanden met de volgende extenties kunnen worden geupload:"])}
    },
    "onBoarding": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meld je aan en beschik over je persoonlijke verhalenbox om zelf foto’s of informatie aan de collectie toe te voegen!"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op trefwoorden om de ‘Collectie van de Gentenaar’ te doorzoeken"])},
      "storybox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via de verhalenbox kan je zelf foto’s, informatie of verhalen toevoegen aan de collectie"])},
      "stories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bent helemaal klaar om zelf objecten of reacties toe te voegen aan de Collectie van de Gentenaar! Veel plezier! Graag nog even de handleiding bekijken? Die vind je <a class='underline' href='https://www.collectie.gent/collectie-van-de-gentenaar-online' target='_blank'>hier</a>"])}
    },
    "cookieConsent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarvoor gebruiken we cookies?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We gebruiken cookies om verkeer en voorkeuren bij te houden, ter verbetering van de gebruikservaring, voor een juiste wisselwerking met sociale media en voor gerichtere communicatie. Je kunt op elk moment je voorkeuren aanpassen onderaan de pagina. <a href='https://stad.gent/nl/cookiebeleid-stad-gent' target='_blank' class='underline cursor-pointer'>Info over het cookiebeleid.</a>"])},
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles accepteren"])},
      "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkeuren instellen"])},
      "preferenceModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie voorkeuren"])}
    }
  },
  "header": {
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek de collectie"])},
    "pavilion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het paviljoen"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulp nodig?"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik je code"])},
    "coghentBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoGent-box"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteiten"])}
  },
  "footer": {
    "eu-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit project wordt mee gefinancierd door het Europees Fonds voor Regionale Ontwikkeling via het initiatief Urban Innovative Actions (UIA)"])},
    "cookie-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cookie instellingen"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "user-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersvoorwaarden"])}
  },
  "buttons": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
    "storybox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhalenbox"])},
    "addToStorybox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan mijn verhalenbox toevoegen"])},
    "all-works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle werken"])},
    "surprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat je verrassen"])}
  },
  "details": {
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek ook andere gerelateerde werken"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer info over dit werk"])},
    "testimony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praat mee:"])},
    "addTestimony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertel hier iets over"])},
    "testimonyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deel hier jouw reactie"])},
    "modal": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link naar object kopiëren"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie wijzigen of aanvullen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan mijn verhalenbox toevoegen"])},
      "addShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
      "objectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectnummer"])},
      "objectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectnaam"])},
      "collectieNaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
      "instellingNaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instelling"])},
      "associations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associaties"])},
      "bron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
      "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenmerken"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
      "createStorybox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak eerst een verhaal aan"])}
    },
    "notFound": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit asset kon "])},
      "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["niet "])},
      "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gevonden worden"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misschien was je op zoek naar één van deze werken"])}
    },
    "offensiveContent": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden kwetsende content"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectie van de Gentenaar ontsluit diverse cultureel erfgoedcollecties, zowel van musea, archieven als van de Gentenaars zelf. Er worden inspanningen geleverd om kwetsende content te voorzien van duiding, met aandacht voor meerstemmigheid. Ben je niet akkoord met de duiding, ontbreekt er informatie of heb je vragen over hetgeen is afgebeeld? Neem dan contact op met <a class='underline' href = 'mailto: info", "@", "collectie.gent'>info", "@", "collectie.gent<a/> en dan bekijken we samen hoe we een verbeterde context kunnen schetsen. Je kan de visie van Collectie van de Gentenaar over kwetsende content nalezen in het <a class='underline' href='/Publicatiebeleid_CoGhent.pdf' download>publicatiebeleid</a>."])}
    }
  },
  "creator": {
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar"])},
    "moreWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer van"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "geslacht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslacht"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationaliteit"])},
    "death_datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterfdatum"])},
    "death_plaats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterfplaats"])},
    "birth_datum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
    "birth_plaats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboorteplaats"])},
    "noMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen details gevonden over deze maker"])}
  },
  "disclaimer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom op de  Collectie van de Gentenaar."])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier ontdek je een selectie uit de collecties van Archief Gent, Design Museum Gent, Huis van Alijn, Industriemuseum en STAM. Achter de schermen werkt een fantastisch team verder om deze collecties te laten groeien, waardoor je hier op regelmatige basis nieuw erfgoed zult ontdekken. Ook de website zelf wordt nog verder doorontwikkeld. Ga gerust al eens rondkijken en heb je verbeterpunten? Dan horen we het graag via"])},
    "content-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wil je meer weten over het project Collectie van de Gentenaar? Neem dan"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "content-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een kijkje."])},
    "small-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier groeit"])},
    "small-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een collectie"])},
    "small-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "profile": {
    "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoi"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account details"])},
    "stories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn verhalen"])},
    "works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn objecten"])},
    "testimonies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn getuigenissen"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profielfoto"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig account"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder account"])},
    "delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door je account te verwijderen gaan al je gegevens definitief verloren"])},
    "delete-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verwijderen"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
    "storybox": {
      "creation-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op "])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code: "])},
      "create": {
        "create-storybox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een verhaal zonder code"])},
        "create-storybox-with-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb al een code uit de box"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van het verhaal"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
        "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code van je box bezoek"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhaal toevoegen"])}
      }
    },
    "testimony": {
      "noTestimonies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt nog geen getuigenissen"])},
      "deleteConfirmation": {
        "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je deze getuigenis wilt verwijderen?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, verwijder deze getuigenis"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neen"])}
      }
    }
  },
  "visit": {
    "linkCodeToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhalenbox aan account toevoegen"])}
  },
  "copyright": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles wat je wilt weten over de rechtenstatussen"])},
    "whatAreCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat zijn Creative Commons?"])},
    "whatAreCCText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creative Commons zijn licenties die door de rechthebbenden worden toegekend aan het werk. Deze licenties geven een helder kader wat betreft het hergebruik van de digitale reproducties. Men kan de digitale reproducties hergebruiken zonder toestemming van de rechthebbende(n), mits toepassing van de voorwaarden verbonden aan de toegekende Creative Commons licentie. <br/><i>Meer informatie over Creative Commons vind je op <a class='underline' href='https://creativecommons.org' target='_blank'>https://creativecommons.org</a>.</i>"])},
    "whatAreRS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat zijn Rights Statements?"])},
    "whatAreRSText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights Statements zijn rechtenverklaringen die de rechtenstatus van een gedigitaliseerd werk aangeven. Deze kunnen toegekend worden door rechthebbenden maar ook door niet-rechthebbenden. Als je een digitale reproductie met een Rights Statement wilt hergebruiken, is het de verantwoordelijkheid van de hergebruiker om na te gaan of er (bijkomende) toestemming van de rechthebbende(n) nodig is. <br/><i>Meer informatie over Rights Statements vind je op <a class='underline' href='https://rightstatements.org' target='_blank'>https://rightsstatements.org</a>.</i>"])},
    "whatRSUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke rechtenstatussen worden gebruikt door CoGhent?"])},
    "whatRSUsedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnen CoGhent worden zowel Creative Commons licenties als Rights Statements gebruikt. Welke rechtenstatussen gebruikt worden en wat de voorwaarden zijn wat betreft hergebruik van de digitale reproducties, kan je hieronder terugvinden."])},
    "whatIsReuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat verstaan we onder ‘hergebruik’?"])},
    "whatIsReuseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het project Collectie van de Gentenaar wil hergebruik van de collectie zoveel mogelijk aanmoedigen. Hergebruik omvat kopiëren, veranderen, verspreiden, zowel online als offline."])},
    "publicDomainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Domain"])},
    "PDM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Domain Mark 1.0"])},
    "PDMText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zowel het gefotografeerde object als de digitale reproductie zijn rechtenvrij. Je kan de digitale reproductie downloaden in groot formaat en Er rusten geen rechten op het werk en er zijn geen voorwaarden wat betreft hergebruik. Het werk mag gekopieerd, veranderd, verspreid en uitgevoerd worden, zelfs voor commerciële doeleinden. Je kan de foto downloaden en hergebruiken, zonder risico."])},
    "creativeCommonsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creative Commons"])},
    "CC0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC0 1.0"])},
    "CC0Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De rechthebbende ziet af van zijn rechten en kent het werk toe aan het publiek domein. Er rusten geen rechten op het werk. Er zijn geen voorwaarden wat betreft hergebruik. Het werk mag gekopieerd, veranderd, verspreid en uitgevoerd worden, zelfs voor commerciële doeleinden. Je kan de foto downloaden en hergebruiken, zonder risico."])},
    "CC-BY-SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC BY-SA 4.0"])},
    "CC-BY-SAText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De rechthebbende heeft de licentie toegekend. Er rusten rechten op het werk. Het werk mag gekopieerd, veranderd, verspreid en uitgevoerd worden, zelfs voor commerciële doeleinden, op voorwaarde dat de naam van de rechthebbende vermeld wordt. Indien het werk veranderd is, moet dit aangegeven worden en de afgeleide moet gedeeld worden onder dezelfde licentie als het origineel. Je kan de foto downloaden en hergebruiken, rekening houdend met bovenstaande voorwaarden."])},
    "CC-BY-NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC BY-NC 4.0"])},
    "CC-BY-NCText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een overeenkomst afgesloten tussen de collectiebeherende instelling en de auteursrechthebbende waarin deze laatste laat noteren dat hij/zij de licentie toekent aan het object. Er blijven auteursrechten rusten op het werk maar hergebruik van de digitale reproductie is mogelijk. De digitale reproductie is downloadbaar in klein formaat en mag verspreid en veranderd worden, op voorwaarde dat de naam van de auteursrechthebbende vermeld wordt en het enkel voor niet-commerciële doeleinden gebruikt wordt."])},
    "CC-BY-NC-ND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC BY-NC-ND 4.0"])},
    "CC-BY-NC-NDText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De rechthebbende heeft de licentie toegekend. Er rusten rechten op het werk. Het werk mag gekopieerd en verspreid worden in de originele vorm, op voorwaarde dat de naam van de rechthebbende vermeld wordt en het enkel voor niet-commerciële doeleinden gebruikt wordt. Het werk mag niet aangepast worden en er mogen geen afgeleiden gemaakt worden."])},
    "rightsStatementsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Statements"])},
    "InCopyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Copyright"])},
    "InCopyrightText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er rusten rechten op het werk. Je kan de foto downloaden in lage resolutie maar je mag deze niet hergebruiken. Wil je het werk toch hergebruiken, dan moet je zelf toestemming vragen aan de rechthebbende."])},
    "InCopyrightNonCommercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Copyright - non-commercial use permitted"])},
    "InCopyrightNonCommercialText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze rechtenstatus wordt hoofdzakelijk toegekend aan digitale reproducties van foto’s die geschonken zijn door derden aan de collectiebeherende instelling. De schenking is vastgelegd in een overeenkomst, waardoor beperkt hergebruik mogelijk is. Er rusten auteursrechten op de foto’s en gezien het onderwerp van de foto’s rust er een beperking op het hergebruik: de digitale reproducties kunnen niet gebruikt worden voor commerciële doeleinden."])},
    "InCopyrightUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Copyright - unknown rightsholder"])},
    "InCopyrightUnknownText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het werk valt nog onder auteursrecht maar de collectiebeherende instelling heeft na onderzoek de rechthebbende niet kunnen identificeren of lokaliseren. De collectiebeherende instelling heeft de rechtenstatus In Copyright - unknown rightsholder toegekend aan de digitale reproductie. De digitale reproductie is downloadbaar in klein formaat maar mag niet hergebruikt worden. De rechtenverklaring is toegekend door een derde gezien de rechthebbende niet lokaliseerbaar en/of identificeerbaar is. In geval van CoGhent is de rechtenverklaring toegekend door de cultureel erfgoedinstelling die het werk in de collectie heeft. Er rusten rechten op het werk. Je bent zelf verantwoordelijk voor het gebruik van het werk. Bij hergebruik is de wetgeving betreffende auteursrecht van toepassing."])},
    "InCopyrightUndetermined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Undetermined"])},
    "InCopyrightUndeterminedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De rechtenverklaring is toegekend door een derde gezien de rechthebbende niet lokaliseerbaar en/of identificeerbaar is. In het geval van Copyright Undetermined is het niet zeker of er auteursrechten rusten op het werk. De rechtenverklaring is toegekend door de cultureel erfgoedinstelling die het werk in de collectie heeft. Er rusten rechten op het werk. Je bent zelf verantwoordelijk voor het gebruik van het werk. Bij hergebruik is de wetgeving betreffende auteursrecht van toepassing."])},
    "NoKnownCopyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Known Copyright"])},
    "NoKnownCopyrightText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De rechtenverklaring is toegekend door een derde gezien de rechthebbende niet lokaliseerbaar en/of identificeerbaar is. In het geval van No Known Copyright is het niet zeker of er auteursrechten rusten op het werk. De rechtenverklaring is toegekend door de cultureel erfgoedinstelling die het werk in de collectie heeft. Er rusten rechten op het werk. Je bent zelf verantwoordelijk voor het gebruik van het werk. Bij hergebruik is de wetgeving betreffende auteursrecht van toepassing."])}
  },
  "storybox": {
    "noStories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt nog geen verhalen"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak jouw verhaal"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouw verhalenbox"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit verhaal wijzigen"])},
    "copyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code kopiëren"])},
    "showQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR code tonen"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan deze code in de box om jouw verhaal te starten"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit verhaal verwijderen"])},
    "deleteConfirmation": {
      "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je dit verhaal wilt verwijderen?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, verwijder dit verhaal"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neen"])}
    },
    "closeConfirmation": {
      "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je wilt stoppen met bewerken?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, maak mijn veranderingen ongedaan"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neen"])}
    },
    "assets": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde werken"])},
      "selectedAssetsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versleep de foto’s om de volgorde te veranderen waarin ze te zien zullen zijn. Dit kan van belang zijn voor het verhaal dat je vertelt. Aan de hand van de cijfers kan je ook in de teskt verwijzen naar een bepaald werk."])},
      "addMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg nog meer werken toe aan je verhaal!"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelf een werk toevoegen"])},
      "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken in de collectie"])},
      "showTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonen gedurende:"])},
      "customText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef hier een eigen beschrijving bij het beeld..."])},
      "showTimeToolTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer het verhaal in de cogent box bekeken wordt zal dit asset gedurende het geselecteerde aantal seconden getoond worden"])}
    },
    "new": {
      "onlyNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve enkel nummers in te voeren"])},
      "codeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code moet 8 karakters bevatten"])},
      "nameLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam moet minimum 5 karakters bevatten"])},
      "codeDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code bestaat niet"])},
      "linkedCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze code bestaat niet of is reeds gelinkt aan een gebruiker"])}
    },
    "selectFrame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer het verhaal dat je wilt aanpassen"])},
    "story": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn verhaal"])},
      "storyPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type hier jou verhaal.."])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhaal afronden"])}
    },
    "step2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rond je verhaal af in twee stappen:"])},
      "storyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een titel aan je verhaal"])},
      "storyLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de taal waarin je verhaal geschreven is:"])}
    },
    "step3": {
      "thankyou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt om je verhaal met ons te delen!"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt om je verhaal met ons te delen! We hebben je verhaal goed ontvangen en kijken alles eventjes na. Je kan de status opvolgen in je account bij Mijn verhalen. Het zou kunnen dat we nog contact met je opnemen als we extra informatie nodig hebben."])}
    }
  },
  "myWorks": {
    "overview": {
      "deleteConfirmation": {
        "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben je zeker dat je dit asset wilt verwijderen?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, verwijder dit asset"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neen"])}
      },
      "statuses": {
        "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgewezen"])},
        "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepubliceerd"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In behandeling"])}
      }
    },
    "upload": {
      "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload je eigen objecten en draag zo bij aan de collectie!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "stepOne": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fijn dan je wil bijdragen aan onze collectie! We zijn benieuwd naar jouw object…"])},
        "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep je bestand in dit venster"])},
        "uploadFromComputer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of upload vanop je computer"])}
      },
      "stepTwo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan je ons vertellen wie dit object gemaakt heeft?"])},
        "options": {
          "owner": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben zelf de maker en rechthebbende van het object."])},
            "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijvoorbeeld, het toegevoegde object is een foto. De foto heb je zelf genomen."])},
            "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met het <a class='underline' href='https://static1.squarespace.com/static/5fb4f5b0509a072b93557287/t/6308c1cb66138b3efda3ca7d/1661518285625/20220826_Publicatiebeleid+CoGhent+1.0.pdf' target='_blank'>publicatiebeleid</a> en geef toestemming om deze digitale reproductie van het object onder <a class='underline' href='https://creativecommons.org/publicdomain/zero/1.0/' target='_blank'>CC0</a> te delen. Dit houdt in dat de reproductie zonder voorwaarden mag gebruikt worden door derden, ook voor commerciële doeleinden."])}
          },
          "uploader": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iemand anders heeft het object gemaakt."])},
            "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijvoorbeeld, je voegt een foto toe waarop een meubel is afgebeeld. Het meubel is ontworpen door iemand anders."])},
            "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met het <a class='underline' href='https://static1.squarespace.com/static/5fb4f5b0509a072b93557287/t/6308c1cb66138b3efda3ca7d/1661518285625/20220826_Publicatiebeleid+CoGhent+1.0.pdf' target='_blank'>publicatiebeleid</a> en heb toestemming van de maker of rechthebbende om deze digitale reproductie van het object onder <a class='underline' href='https://creativecommons.org/publicdomain/zero/1.0/' target='_blank'>CC0</a> te delen. Dit houdt in dat de reproductie zonder voorwaarden mag gebruikt worden door derden, ook voor commerciële doeleinden. Indien het object of de foto ouder is dan 150 jaar wordt de reproductie gedeeld onder een <a class='underline' href='https://creativecommons.org/publicdomain/mark/1.0/' target='_blank'>Public Domain Mark 1.0</a>."])}
          },
          "disclaimerPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als er personen herkenbaar zijn afgebeeld heb toestemming van deze personen om het object toe te voegen. Indien minderjarigen herkenbaar worden afgebeeld op jouw materiaal, moet je toestemming hebben van ouders of voogden alvorens het materiaal toe te voegen op data.collectie.gent. Toestemming houdt in dat derden/ouders/voogden op de hoogte zijn van en akkoord gaan met de gebruiksvoorwaarden van data.collectie.gent."])}
        }
      },
      "stepThree": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ken je meer gegevens over de oorspronkelijke maker van het object?"])},
        "metadata": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie die we heel graag kennen"])},
          "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Originele) titel van het object"])},
          "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving (waarover gaat dit object, wat is er afgebeeld, …)"])},
          "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie heeft het object gemaakt( fotograaf, kunstenaar, …)?"])},
          "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer is het object gemaakt (datum, periode, …)?"])}
        },
        "relation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg tags toe aan het object"])},
          "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoek relaties"])},
          "relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaties"])}
        }
      },
      "stepFour": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijk zeker nog even na of alle gegevens over het object dat je wil bijdragen kloppen."])},
        "relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaties"])}
      },
      "stepDone": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt voor je bijdrage aan onze collectie!"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben alle gegevens goed ontvangen en kijken nu alles na. We sturen je een berichtje van zodra het object goedgekeurd is en op de website staat. Het zou kunnen dat we nog contact opnemen voor extra informatie, nemen foto's, ..."])},
        "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt om onze collectie nog groter te maken!"])}
      },
      "steps": {
        "stepOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand opladen"])},
        "stepTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details aanvullen"])},
        "stepThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object verifiëren"])},
        "stepFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])}
      }
    }
  },
  "flow": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "Collectie.naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
  "Entiteit.classificatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type object"])},
  "MaterieelDing.beheerder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instelling"])},
  "MaterieelDing.bestaatUit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderdelen"])},
  "object_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objectcategorie"])},
  "Classificatie.toegekendType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maakt deel uit van"])},
  "MaterieelDing.isOvergedragenBijVerwerving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkomst"])},
  "Verwerving.overgedragenAan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overdracht aan"])},
  "verwerving.plaats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaats van verwerving"])},
  "verwerving.methode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwervingsmethode"])},
  "Periode.begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["begin periode"])},
  "Periode.einde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einde periode"])},
  "Entiteit.wordtNaarVerwezenDoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontworpen door"])},
  "MaterieelDing.productie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaardigd door"])},
  "Gebeurtenis.tijd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productiedatum"])},
  "vervaardiging.plaats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaats van vervaardiging"])},
  "vervaardiger.rol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rol"])},
  "Entiteit.maaktDeelUitVan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentoonstellingen"])},
  "gebruiktBijActiviteit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tentoonstelling"])},
  "MensgemaaktObject.draagt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associaties"])},
  "associatie.onderwerp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geassocieerd onderwerp"])},
  "associatie.periode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geassocieerde periode"])},
  "associatie.persoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geassocieerde persoon/instelling"])},
  "inhoud.onderwerp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgebeeld onderwerp"])},
  "inhoud.persoon.naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgebeeld persoon/instelling"])},
  "inhoud.onderwerp.eigennaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afgebeeld onderwerp"])}
}