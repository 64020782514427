import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-end" }
const _hoisted_2 = { class: "pb-4 flex justify-end mt-4" }
const _hoisted_3 = { class: "md:flex md:mt-4 gap-8" }
const _hoisted_4 = { class: "w-full p-4 md:p-0" }
const _hoisted_5 = { class: "w-full flex justify-end mb-8 sm:mb-0" }
const _hoisted_6 = { class: "md:hidden fixed bottom-16 right-10 bg-accent-purple rounded-full p-4 flex justify-center items-center shadow-lg" }
const _hoisted_7 = {
  key: 0,
  class: "h-fit p-8 flex flex-col w-full justify-center items-center overflow-hidden"
}
const _hoisted_8 = { class: "flex justify-center items-center w-full p-4" }
const _hoisted_9 = {
  key: 1,
  class: "flex items-center flex-col w-full h-full"
}
const _hoisted_10 = {
  key: 0,
  class: "h-fit mt-12 text-xl"
}
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 0,
  class: "w-full flex justify-end items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRCodeModal = _resolveComponent("QRCodeModal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_profile_side_menu = _resolveComponent("profile-side-menu")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_CircleLoader = _resolveComponent("CircleLoader")!
  const _component_profile_list_item = _resolveComponent("profile-list-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_QRCodeModal),
    _createVNode(_component_confirmation_modal, {
      modalText: _ctx.t('storybox.deleteConfirmation.confirmationText'),
      confirmButtonText: _ctx.t('storybox.deleteConfirmation.yes'),
      declineButtonText: _ctx.t('storybox.deleteConfirmation.no')
    }, null, 8, ["modalText", "confirmButtonText", "declineButtonText"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: "/mijnverhalen/new" }, {
          default: _withCtx(() => [
            _createVNode(_component_base_button, {
              class: "hidden md:flex text-text-white",
              "icon-shown": true,
              "custom-icon": "newItem",
              text: _ctx.t('storybox.createNew')
            }, null, 8, ["text"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_profile_side_menu),
      _createElementVNode("section", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, { to: "/mijnverhalen/new" }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseIcon, {
                  icon: "newItem",
                  class: "stroke-current p-1 cursor-pointer text-text-white"
                })
              ]),
              _: 1
            })
          ])
        ]),
        (_ctx.loading && _ctx.storyBoxItems.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_CircleLoader)
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.loading && _ctx.storyBoxItems.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              true
                ? (_openBlock(), _createElementBlock("h1", _hoisted_10, _toDisplayString(_ctx.t(`storybox.noStories`)), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.storyBoxItems.length > 0 && !_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_ctx.pager.pageAmount.value > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_BaseIcon, {
                      icon: "arrowLeftLine",
                      class: "stroke-current p-2 cursor-pointer",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pager.goToPreviousPage(_ctx.getStoryBoxes)))
                    }),
                    _createElementVNode("p", null, _toDisplayString(`${_ctx.pager.currentPage.value} of ${_ctx.pager.pageAmount.value}`), 1),
                    _createVNode(_component_BaseIcon, {
                      icon: "arrowRightLine",
                      class: "stroke-current p-2 cursor-pointer",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pager.goToNextPage(_ctx.getStoryBoxes)))
                    })
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storyBoxItems, (storyBoxItem, index) => {
                return (_openBlock(), _createBlock(_component_profile_list_item, {
                  key: index,
                  "profile-list-item-info": storyBoxItem,
                  onUpdate: _ctx.getStoryBoxes
                }, null, 8, ["profile-list-item-info", "onUpdate"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}