import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_AddAssetToStoryboxDropdown = _resolveComponent("AddAssetToStoryboxDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid items-center cursor-pointer bg-background-medium bg-opacity-75", [_ctx.itemAction === 'deleted' ? 'grid-cols-1' : 'grid-cols-3 grid-row-1 sm:grid-cols-1 sm:grid-row-3']])
  }, [
    (_ctx.itemAction !== 'deleted')
      ? (_openBlock(), _createBlock(_component_BaseIcon, {
          key: 0,
          id: "edit",
          icon: 'edit',
          class: "row-span-1 h-full p-4 flex justify-center items-center stroke-current text-accent-purple",
          onClick: _cache[0] || (_cache[0] = () => (_ctx.isLoading ? null : _ctx.router.push(_ctx.links.edit)))
        }))
      : _createCommentVNode("", true),
    (_ctx.itemAction !== 'deleted')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([[_ctx.assetIsAddedToStoryBox === true ? 'bg-opacity-90 bg-text-white' : ''], "h-full flex items-center justify-center"])
        }, [
          _createVNode(_component_AddAssetToStoryboxDropdown, {
            skidding: 0,
            distance: 0,
            placement: 'auto',
            trigger: _ctx.openStoryboxes,
            entity: _ctx.myWorksItem.entity,
            onClick: _cache[1] || (_cache[1] = () => (_ctx.isLoading ? null : (_ctx.openStoryboxes = !_ctx.openStoryboxes))),
            onAddToStorybox: _cache[2] || (_cache[2] = (ids) => _ctx.addAssetToStorybox(ids))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseIcon, {
                id: "storybox",
                icon: _ctx.assetIsAddedToStoryBox.length ? 'check' : 'storybox',
                class: _normalizeClass([[_ctx.assetIsAddedToStoryBox.length ? 'text-text-white bg-accent-purple' : 'text-accent-purple'], "row-span-1 h-full p-4 flex justify-center items-center stroke-current"])
              }, null, 8, ["icon", "class"])
            ]),
            _: 1
          }, 8, ["trigger", "entity"])
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_BaseIcon, {
      id: "delete",
      icon: _ctx.itemAction === 'deleted' ? 'undo' : 'delete',
      class: _normalizeClass([_ctx.itemAction === 'deleted' ? 'text-accent-purple' : 'text-text-red', "row-span-1 h-full p-4 flex justify-center items-end stroke-current"]),
      onClick: _cache[3] || (_cache[3] = () => (_ctx.isLoading ? null : _ctx.itemAction === 'deleted' ? _ctx.deleteRestoreAsset() : _ctx.openConfirm()))
    }, null, 8, ["icon", "class"])
  ], 2))
}