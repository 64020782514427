
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { BaseButton, BaseInput } from 'coghent-vue-3-component-library'
import { useI18n } from 'vue-i18n'
import { User } from 'coghent-vue-3-component-library'
import { UserStore } from '../stores/UserStore'
import { ConfigStore } from '../stores/ConfigStore'
import StoreFactory from '../stores/StoreFactory'
import ProfileSideMenu from '../components/ProfileSideMenu.vue'
import { useSessionAuth } from '@/app'

export default defineComponent({
  components: { BaseButton, BaseInput, ProfileSideMenu },
  setup() {
    const router = useRouter()
    const userStore = StoreFactory.get(UserStore)
    const user: typeof User = userStore.user
    const configStore = StoreFactory.get(ConfigStore)
    const userEditUrl = configStore.config.value.userEditUrl

    //temp
    const buttonClick = () => {}

    const getEditPage = () => {
      if (userStore.hasUser) {
        const editRoute = userEditUrl + userStore.user.value.id
        return editRoute
      } else return
    }

    const logout = async () => {
      console.log(`STEP 1 | WEB LOGOUT `)
      fetch('/api/logout')
        .then(async (response) => {
          userStore.setUser(null)
          useSessionAuth.resetAuthProperties()
          router.push('/')
        })
        .catch((error) => {
          router.push('/')
          console.log(`WEB | Couldn't logout`, { error })
        })
    }

    const { t } = useI18n()

    return {
      buttonClick,
      userStore,
      user,
      getEditPage,
      t,
      logout,
    }
  },
})
