import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-full justify-center items-center p-4" }
const _hoisted_2 = {
  id: "js-pdf",
  class: "w-full flex flex-grow flex-col"
}
const _hoisted_3 = { class: "w-full mb-8" }
const _hoisted_4 = { class: "font-bold text-4xl" }
const _hoisted_5 = { class: "flex-grow flex justify-center items-center flex-col" }
const _hoisted_6 = { class: "w-full flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_BoxVisitCode = _resolveComponent("BoxVisitCode")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    large: true,
    "modal-state": _ctx.QRCodeModalState.state,
    onHideModal: _ctx.closeQRCodeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.t('storybox.scan')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_qrcode_vue, {
              value: _ctx.parseStoryboxUrl(),
              size: 300,
              level: "H"
            }, null, 8, ["value"]),
            _createVNode(_component_BoxVisitCode, {
              code: _ctx.QRCodeModalState.code,
              class: "mt-12"
            }, null, 8, ["code"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BaseButton, {
            text: _ctx.t('storybox.download'),
            "icon-shown": false,
            "on-click": _ctx.convertHtmlToPdf
          }, null, 8, ["text", "on-click"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modal-state", "onHideModal"]))
}