
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TheHeader from '@/components/TheHeader.vue'
import { useHead } from '@vueuse/head'
import { ConfigStore, useGoogleFeature, useStoryboxFeature } from './stores/ConfigStore'
import StoreFactory from './stores/StoreFactory'
import CreativeModal from './components/CreativeModal.vue'
import DetailsModal from './components/DetailsModal.vue'
import DisclaimerPopUp from './components/DisclaimerPopUp.vue'
import DisclaimerButton from './components/DisclaimerButton.vue'
import TheFooter from './components/TheFooter.vue'
import MobileMenu from './components/MobileMenu.vue'
import { addGoogleData } from '@/composables/gtm'
import Notification from '@/components/Notification.vue'
import OffensiveContentModal from './components/OffensiveContentModal.vue'
import OnScreenKeyboard from './components/OnScreenKeyboard.vue'
import { useOnScreenKeyboard } from './composables/useOnScreenKeyboard'
import onBoardingCarousel from './components/onBoardingCarousel.vue'
import { useOnBoarding } from './composables/useOnBoarding'
import { MinimalCookieConsent, CookiePreferencesModal, useCookieConsent } from 'coghent-vue-3-component-library'
import { getCookie } from 'tiny-cookie'
import { usedCookies } from './composables/usedCookies'

export default defineComponent({
  name: 'App',
  components: {
    TheHeader,
    CreativeModal,
    DetailsModal,
    TheFooter,
    DisclaimerButton,
    DisclaimerPopUp,
    MobileMenu,
    Notification,
    OffensiveContentModal,
    OnScreenKeyboard,
    onBoardingCarousel,
    MinimalCookieConsent,
    CookiePreferencesModal,
  },
  setup: () => {
    const { t } = useI18n()
    const configStore = StoreFactory.get(ConfigStore)
    const indexValue: boolean | undefined = configStore.config.value.vueAppIndex
    const route = useRoute()
    const mobileMenuIsOpen = ref<boolean>(false)
    const { keyboardState } = useOnScreenKeyboard()
    const { onboardingState } = useOnBoarding()
    const { setUsedCookies, closeConsentManager, setUserAcceptedCookies, cookieConsentState } = useCookieConsent()
    const gtmId = configStore.config.value.google?.tagManager
    const gaId = configStore.config.value.google?.analytics
    const userCookiePreference = getCookie('_cookiePreferences')

    if (userCookiePreference) {
      const userAcceptedCookies = JSON.parse(userCookiePreference || '[]')
      setUserAcceptedCookies(userAcceptedCookies)
      closeConsentManager()
    }

    setUsedCookies(usedCookies)

    if (route.query.touch) {
      // Disable rightclick if touchmode
      document.addEventListener('contextmenu', (event) => event.preventDefault())
    }

    const getIndexValue = () => {
      let indexStr = ''
      if (indexValue === true) {
        indexStr = 'INDEX, FOLLOW'
      } else if (indexValue === undefined || indexValue === false) {
        indexStr = 'NOINDEX, NOFOLLOW'
      }
      return indexStr
    }

    useHead({
      meta: [
        {
          name: `ROBOTS`,
          content: getIndexValue(),
        },
      ],
    })

    watch(
      () => cookieConsentState.value.userAcceptedCookies,
      (userAcceptedCookies) => {
        if (userAcceptedCookies.includes('_ga') && useGoogleFeature.value === true) {
          addGoogleData(gtmId, gaId)
        }
      },
      { immediate: true }
    )

    return {
      t,
      route,
      mobileMenuIsOpen,
      keyboardState,
      onboardingState,
      useStoryboxFeature,
    }
  },
})
