
import { notification } from '@/composables/useNotifications'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const notifications = notification.notifications
    return {
      notifications,
    }
  },
})
